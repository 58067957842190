import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { LOAD_PRICE_RULE, LOAD_PRICE_RULE_BY_ID } from './query-keys';
import { type Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type priceRuleItem = { id: number | string; type: string };

export type PriceRule = {
	code: string;
	priceRuleId: string;
	priceRuleConfiguration: PriceRuleConfiguration;
};

type PriceRuleConfiguration = {
	id: string;
	type: string;
	amount: number;
	subtotalMinimum?: number;
	shippingMaximum?: number;
	quantityMinimum?: number;
	oncePerCustomer: boolean;
	startsAt: Date;
	endsAt?: Date;
};

export function useGetPriceRule() {
	const queryClient = useQueryClient();
	const transform = useDateTransformerFactory('startsAt', 'endsAt');
	return useCallback((code: string) => transform(queryClient.getQueryData(queryKey(LOAD_PRICE_RULE, code))), [queryClient, transform]);
}

export function useLoadPriceRule({ code, cdnCache = false }: { code: string; cdnCache?: boolean }) {
	const { get } = useAuthenticatedFetch() as Get<PriceRule>;
	const { data: priceRule, isFetching } = useQuery({
		queryKey: queryKey(LOAD_PRICE_RULE, code),
		queryFn: () =>
			get(`/price-rules/${encodeURIComponent(code)}?cdnCache=${cdnCache}`).then(data => ({ ...data, ...data?.priceRuleConfiguration })),
		select: useDateTransformerFactory('startsAt', 'endsAt'),
		enabled: !!code
	});
	return { priceRule, isPriceRuleLoading: isFetching };
}

export function useGetPriceRuleById() {
	const queryClient = useQueryClient();
	const transform = useDateTransformerFactory('startsAt', 'endsAt');
	return useCallback((id: string) => transform(queryClient.getQueryData(queryKey(LOAD_PRICE_RULE_BY_ID, id))), [queryClient, transform]);
}

export function useLoadPriceRuleById(id: string) {
	const { get } = useAuthenticatedFetch() as Get<PriceRule>;
	const { data: priceRuleById, isFetching } = useQuery({
		queryKey: queryKey(LOAD_PRICE_RULE_BY_ID, id),
		queryFn: () => get(`/price-rules/id/${id}`).then(data => ({ ...data, ...data?.priceRuleConfiguration })),
		select: useDateTransformerFactory('startsAt', 'endsAt'),
		enabled: !!id
	});
	return { priceRuleById, isPriceRuleByIdLoading: isFetching };
}
