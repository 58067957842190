import { LoadingScreen } from '@segunosoftware/equinox';
import { useEffect, useRef, useState } from 'react';
import { useAccountFetch } from '../hooks/useAccount';
import { useGetBannerConfiguration } from '../hooks/useBannerConfiguration';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const [appBridgeLoaded, setAppBridgeLoaded] = useState(false);
	const appBridgeScriptInjected = useRef(false);

	useEffect(() => {
		if (!appBridgeScriptInjected.current) {
			appBridgeScriptInjected.current = true;
			const appBridgeScript = document.createElement('script');
			appBridgeScript.src = 'https://cdn.shopify.com/shopifycloud/app-bridge.js';
			appBridgeScript.async = false;
			appBridgeScript.onload = () => setAppBridgeLoaded(true);
			document.head.prepend(appBridgeScript);
		}
	}, []);

	if (appBridgeLoaded) {
		return <ProtectedRouteWithAppBridge element={element} />;
	}

	return <LoadingScreen />;
}

type ProtectedRouteWithAppBridgeProps = {
	element: JSX.Element;
};

function ProtectedRouteWithAppBridge({ element }: ProtectedRouteWithAppBridgeProps) {
	const { account } = useAccountFetch(true);

	// Pre-fetch the banner, this probably should've been in the account model to begin with
	// since there's only one
	useGetBannerConfiguration();

	if (!account) {
		return <LoadingScreen />;
	}

	return element;
}
