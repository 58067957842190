import { Heading, Stack } from '@segunosoftware/equinox';
import { Button, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import CustomBannerMessage from './CustomBannerMessage';

const MAXIMUM_MESSAGES = 5;

const CONFIG_PROPERTIES = {
	customLabel: null,
	actionType: 'none',
	actionNewTab: false,
	actionUrl: null,
	actionLabel: null,
	actionSuccessMessage: null,
	actionPlaceholder: null,
	actionSubmitLabel: null,
	actionTags: 'newsletter,banner',
	actionName: null,
	actionNameVisible: false,
	hideCountdown: false
};

export const CUSTOM_BANNER_PROPERTIES = Object.keys(CONFIG_PROPERTIES);

export default class CustomBanner extends Component {
	static propTypes = {
		configuration: PropTypes.shape({
			totalAnnouncements: PropTypes.number.isRequired,
			announcementTimer: PropTypes.number.isRequired,
			customLabel: PropTypes.string,
			actionType: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab: PropTypes.bool.isRequired,
			actionUrl: PropTypes.string,
			actionLabel: PropTypes.string,
			actionPlaceholder: PropTypes.string,
			actionSuccessMessage: PropTypes.string,
			actionSubmitLabel: PropTypes.string,
			actionTags: PropTypes.string,
			actionName: PropTypes.string,
			actionNameVisible: PropTypes.bool,
			hideCountdown: PropTypes.bool,
			customLabel2: PropTypes.string,
			actionType2: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab2: PropTypes.bool.isRequired,
			actionUrl2: PropTypes.string,
			actionLabel2: PropTypes.string,
			actionPlaceholder2: PropTypes.string,
			actionSuccessMessage2: PropTypes.string,
			actionSubmitLabel2: PropTypes.string,
			actionTags2: PropTypes.string,
			actionName2: PropTypes.string,
			actionNameVisible2: PropTypes.bool,
			hideCountdown2: PropTypes.bool,
			customLabel3: PropTypes.string,
			actionType3: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab3: PropTypes.bool.isRequired,
			actionUrl3: PropTypes.string,
			actionLabel3: PropTypes.string,
			actionPlaceholder3: PropTypes.string,
			actionSuccessMessage3: PropTypes.string,
			actionSubmitLabel3: PropTypes.string,
			actionTags3: PropTypes.string,
			actionName3: PropTypes.string,
			actionNameVisible3: PropTypes.bool,
			hideCountdown3: PropTypes.bool,
			customLabel4: PropTypes.string,
			actionType4: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab4: PropTypes.bool.isRequired,
			actionUrl4: PropTypes.string,
			actionLabel4: PropTypes.string,
			actionPlaceholder4: PropTypes.string,
			actionSuccessMessage4: PropTypes.string,
			actionSubmitLabel4: PropTypes.string,
			actionTags4: PropTypes.string,
			actionName4: PropTypes.string,
			actionNameVisible4: PropTypes.bool,
			hideCountdown4: PropTypes.bool,
			customLabel5: PropTypes.string,
			actionType5: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab5: PropTypes.bool.isRequired,
			actionUrl5: PropTypes.string,
			actionLabel5: PropTypes.string,
			actionPlaceholder5: PropTypes.string,
			actionSuccessMessage5: PropTypes.string,
			actionSubmitLabel5: PropTypes.string,
			actionTags5: PropTypes.string,
			actionName5: PropTypes.string,
			actionNameVisible5: PropTypes.bool,
			hideCountdown5: PropTypes.bool
		}).isRequired,
		onChange: PropTypes.func.isRequired,
		onMultiChange: PropTypes.func.isRequired,
		countdownAvailable: PropTypes.bool
	};

	static defaultProps = {
		countdownAvailable: false
	};

	renderCustomBannerMessage(messageNumber) {
		const { configuration, onChange, countdownAvailable } = this.props;
		const getSuffix = str => `${str}${messageNumber > 1 ? messageNumber : ''}`;
		const customOnChange = (value, id) => onChange(value, getSuffix(id));
		const config = Object.keys(CONFIG_PROPERTIES).reduce((acc, curr) => {
			acc[curr] = configuration[getSuffix(curr)];
			return acc;
		}, {});
		const fields = [];
		if (messageNumber > 1) {
			fields.push(
				<Stack distribution="equalSpacing" key={`header-${messageNumber}`}>
					<Heading>Message {messageNumber}</Heading>
					<Button onClick={() => this.onDeleteMessage(messageNumber)} variant="plain">
						Delete message
					</Button>
				</Stack>
			);
		}
		fields.push(
			<CustomBannerMessage
				key={`message-${messageNumber}`}
				configuration={config}
				onChange={customOnChange}
				autoFocus={messageNumber === 1}
				countdownAvailable={countdownAvailable}
			/>
		);
		return fields;
	}

	renderMessages() {
		const {
			configuration: { totalAnnouncements }
		} = this.props;
		const fields = [];
		for (let i = 0; i < totalAnnouncements; i++) {
			fields.push(...this.renderCustomBannerMessage(i + 1));
		}
		return fields;
	}

	addMessage = () => {
		const {
			configuration: { totalAnnouncements },
			onChange
		} = this.props;
		onChange(totalAnnouncements + 1, 'totalAnnouncements');
	};

	onDeleteMessage = messageNumber => {
		const { configuration, onMultiChange } = this.props;
		const totalAnnouncements = configuration.totalAnnouncements;
		const changes = { totalAnnouncements: totalAnnouncements - 1 };
		const configKeys = Object.keys(CONFIG_PROPERTIES);
		for (let i = messageNumber; i < totalAnnouncements; i++) {
			configKeys.forEach(key => {
				changes[`${key}${i}`] = configuration[`${key}${i + 1}`];
			});
		}
		for (let i = totalAnnouncements; i <= MAXIMUM_MESSAGES; i++) {
			configKeys.forEach(key => {
				changes[`${key}${i}`] = CONFIG_PROPERTIES[key];
			});
		}
		if (changes.totalAnnouncements === 1) {
			changes['hideCountdown'] = false;
		}
		onMultiChange(changes);
	};

	onAnnouncementTimerChange = newValue => {
		const { onChange } = this.props;
		onChange(Number(newValue), 'announcementTimer');
	};

	render() {
		const {
			configuration: { totalAnnouncements, announcementTimer }
		} = this.props;
		return (
			<Stack vertical>
				{this.renderMessages()}
				{totalAnnouncements < MAXIMUM_MESSAGES && (
					<Button onClick={this.addMessage} size="slim" variant="primary">
						Add message
					</Button>
				)}
				{totalAnnouncements > 1 && (
					<TextField
						type="number"
						autoComplete="off"
						min={1}
						step={1}
						label="Display timer length"
						helpText="The number of seconds before the next message is displayed."
						value={announcementTimer.toString()}
						onChange={this.onAnnouncementTimerChange}
					/>
				)}
			</Stack>
		);
	}
}
