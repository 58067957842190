import { TagField } from '@segunosoftware/equinox';
import { Checkbox, FormLayout, Select, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

const MAXIMUM_TAGS = 5;

export default class CustomBannerMessage extends Component {
	static propTypes = {
		configuration: PropTypes.shape({
			customLabel: PropTypes.string,
			actionType: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionNewTab: PropTypes.bool.isRequired,
			hideCountdown: PropTypes.bool.isRequired,
			actionUrl: PropTypes.string,
			actionLabel: PropTypes.string,
			actionPlaceholder: PropTypes.string,
			actionTags: PropTypes.string
		}).isRequired,
		onChange: PropTypes.func.isRequired,
		autoFocus: PropTypes.bool,
		countdownAvailable: PropTypes.bool
	};

	static defaultProps = {
		autoFocus: false,
		countdownAvailable: false
	};

	render() {
		const { configuration, onChange, autoFocus, countdownAvailable } = this.props;
		const onValueChanged = id => value => {
			onChange(value, id);
		};
		return (
			<FormLayout>
				<TextField
					key="customLabel"
					type="text"
					label="Message"
					autoComplete="off"
					onChange={onValueChanged('customLabel')}
					value={configuration.customLabel || ''}
					autoFocus={autoFocus}
				/>
				<Select
					label="Action"
					value={configuration.actionType}
					onChange={onValueChanged('actionType')}
					options={[
						{ value: 'none', label: 'None' },
						{ value: 'link_banner', label: 'Link banner' },
						{ value: 'link_button', label: 'Link button' },
						{ value: 'email_capture', label: 'Email capture' }
					]}
				/>
				{configuration.actionType !== 'none' && configuration.actionType !== 'email_capture' && (
					<TextField
						type="url"
						autoComplete="url"
						label="Action URL"
						placeholder="https://"
						onChange={onValueChanged('actionUrl')}
						value={configuration.actionUrl || ''}
					/>
				)}
				{configuration.actionType !== 'none' && configuration.actionType !== 'email_capture' && (
					<Checkbox label="Open in new tab" checked={configuration.actionNewTab} onChange={onValueChanged('actionNewTab')} />
				)}
				{configuration.actionType === 'link_button' && (
					<TextField
						key="actionLabel"
						type="text"
						label="Button text"
						autoComplete="off"
						onChange={onValueChanged('actionLabel')}
						value={configuration.actionLabel || ''}
					/>
				)}
				{configuration.actionType === 'email_capture' && (
					<Checkbox label="Request first name" checked={configuration.actionNameVisible} onChange={onValueChanged('actionNameVisible')} />
				)}
				{configuration.actionType === 'email_capture' && configuration.actionNameVisible && (
					<TextField
						key="actionName"
						type="text"
						label="First name placeholder"
						autoComplete="off"
						onChange={onValueChanged('actionName')}
						value={configuration.actionName || ''}
					/>
				)}
				{configuration.actionType === 'email_capture' && (
					<TextField
						key="actionPlaceholder"
						type="text"
						label="Email placeholder"
						autoComplete="off"
						onChange={onValueChanged('actionPlaceholder')}
						value={configuration.actionPlaceholder || ''}
					/>
				)}
				{configuration.actionType === 'email_capture' && (
					<TextField
						key="actionSubmitLabel"
						type="text"
						label="Submit button text"
						autoComplete="off"
						onChange={onValueChanged('actionSubmitLabel')}
						helpText="An icon will be used if left blank."
						value={configuration.actionSubmitLabel || ''}
					/>
				)}
				{configuration.actionType === 'email_capture' && (
					<TextField
						key="actionSuccessMessage"
						type="text"
						label="Subscribed confirmation message"
						autoComplete="off"
						onChange={onValueChanged('actionSuccessMessage')}
						value={configuration.actionSuccessMessage || ''}
					/>
				)}
				{configuration.actionType === 'email_capture' && (
					<TagField
						key="actionTags"
						label="Customer tags"
						helpText={`Up to ${MAXIMUM_TAGS} tags can be added to the customer after they subscribe.`}
						onChange={tags => onValueChanged('actionTags')(tags.join(','))}
						tags={(configuration.actionTags || '').split(',').map(tag => tag.trim())}
						maximumTags={MAXIMUM_TAGS}
					/>
				)}
				{countdownAvailable && (
					<Checkbox
						label="Show countdown timer for this message"
						checked={!configuration.hideCountdown}
						onChange={value => onValueChanged('hideCountdown')(!value)}
					/>
				)}
			</FormLayout>
		);
	}
}
