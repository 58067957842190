import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useSetDefaultPriceRule = () => {
	const queryClient = useQueryClient();

	const { mutate: onSetDefaultPriceRuleId, isSuccess } = useMutation({
		mutationFn: async (priceRuleId: string) => queryClient.setQueryData(['defaultPriceRuleId'], priceRuleId)
	});

	return { onSetDefaultPriceRuleId, isSuccess };
};

export const useGetDefaultPriceRuleId = () => {
	const queryClient = useQueryClient();
	const defaultPriceRuleId = queryClient.getQueryData(['defaultPriceRuleId']);
	return defaultPriceRuleId;
};
