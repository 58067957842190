import { Card, Stack } from '@segunosoftware/equinox';
import { Banner, Configuration, DiscountConfiguration } from '@segunosoftware/storefront-banner';
import '@segunosoftware/storefront-banner/dist/styles.css';
import { ActionList, Button, FormLayout, Popover, Select } from '@shopify/polaris';
import { add, subDays } from 'date-fns';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import styled from 'styled-components';
import { CUSTOM_BANNER_PROPERTIES } from './CustomBanner';

// const MobileIcon = () => (
// 	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
// 		<path d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" />
// 		<path d="M0 0h24v24H0z" fill="none" />
// 	</svg>
// );

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const RESET_TIMER_IN_MILLIS = 1 * MILLIS_PER_HOUR;

const EXAMPLE_CODE = 'ABC123';

const MOBILE_SCALE = 0.5;

const MOBILE_ORIENTATIONS = {
	portrait: 'Portrait',
	landscape: 'Landscape'
};

const MOBILE_SIZES = {
	'iPhone 5, SE': {
		width: 320,
		height: 568
	},
	'iPhone 6-8': {
		width: 375,
		height: 667
	},
	'iPhone 6-8 Plus': {
		width: 414,
		height: 736
	},
	'Galaxy S3-S7, Note 2-4': {
		width: 360,
		height: 640
	},
	'Galaxy S8, Note 8': {
		width: 360,
		height: 740
	}
};

const DISCOUNT_TYPES = {
	fixed_amount: 'Fixed amount',
	percentage: 'Percentage discount',
	shipping: 'Free shipping'
};

const MINIMUM_TYPES = {
	none: 'No minimum',
	initial: 'Empty cart',
	remaining: 'Minimum remaining',
	reached: 'Minimum reached'
};

const TIERED_MINIMUM_TYPES = {
	initial: 'Empty cart',
	tier_1: 'Tier 1 minimum remaining',
	tier_2: 'Tier 2 minimum remaining',
	tier_3: 'Tier 3 minimum remaining',
	tier_4: 'Tier 4 minimum remaining',
	tier_5: 'Tier 5 minimum remaining',
	reached: 'Minimum reached'
};

const CUSTOMER_STATE_TYPES = {
	unsubscribed: 'Not subscribed',
	subscribed: 'Subscribed'
};

const createProduct = id => ({
	id,
	name: `Product ${id}`,
	type: 'product',
	handle: `product=${id}`,
	image: '/images/placeholder.jpg'
});

const getEndDate = () => add(new Date(), { days: 1, hours: 6 }).getTime();

export default class BannerPreview extends Component {
	static propTypes = {
		configuration: PropTypes.shape({
			backupEnabled: PropTypes.bool.isRequired,
			backupType: PropTypes.oneOf(['discount', 'custom', 'freeShipping']).isRequired,
			backupCodeMessageType: PropTypes.oneOf(['singular', 'dynamic', 'tiered']).isRequired,
			totalTiers: PropTypes.number.isRequired
		}),
		getPriceRule: PropTypes.func.isRequired,
		bannerCurrentlyShowing: PropTypes.bool.isRequired
	};

	state = {
		previewType: 'Default',
		discountPreview: 'fixed_amount',
		minimumState: 'initial',
		previewTypePopover: false,
		discountPreviewPopover: false,
		announcementMessagePopover: false,
		minimumPopover: false,
		mobileSelected: false,
		mobileOrientation: 'portrait',
		mobileSize: 'iPhone 6-8',
		announceMessageState: 'auto',
		customerTypeState: 'unsubscribed',
		endsAt: getEndDate()
	};

	constructor(props) {
		super(props);
		this.bannerContainer = createRef();
		this.mobileContainer = createRef();
	}

	componentDidMount() {
		const { configuration } = this.props;
		this.countdownInterval = setInterval(() => {
			this.setState({
				endsAt: getEndDate()
			});
		}, RESET_TIMER_IN_MILLIS);
		if (configuration.backupEnabled) {
			this.loadBanner();
		}
	}

	componentWillUnmount() {
		this.tearDownBanners();
	}

	componentDidUpdate(prevProps, prevState) {
		const { configuration } = this.props;
		const { previewType, minimumState, announceMessageState } = this.state;
		if (previewType === 'Default' && !configuration.backupEnabled) {
			this.tearDownBanners();
		} else if (
			previewType !== 'Default' &&
			configuration.backupEnabled &&
			(configuration.backupEnabled !== prevProps.configuration.backupEnabled ||
				configuration.backupType !== prevProps.configuration.backupType)
		) {
			this.setPreviewType('Default');
		} else if (announceMessageState !== 'auto' && configuration.totalAnnouncements !== prevProps.configuration.totalAnnouncements) {
			this.setAnnounceMessageState('auto');
		} else if (
			minimumState !== 'initial' &&
			(configuration.totalTiers !== prevProps.configuration.totalTiers || configuration.backupType !== prevProps.configuration.backupType)
		) {
			this.setMinimumState('initial');
		} else {
			this.loadBanner();
		}
	}

	tearDownBanners() {
		clearInterval(this.countdownInterval);
		if (this.banner) {
			this.banner.destroy();
			this.banner = null;
		}
		if (this.mobileBanner) {
			this.mobileBanner.destroy();
			this.mobileBanner = null;
		}
	}

	getConfigForMessageNumber(configuration, config, messageNumber) {
		return configuration[`${config}${messageNumber > 1 ? messageNumber : ''}`];
	}

	loadBanner() {
		const { configuration, getPriceRule } = this.props;
		const { endsAt, discountPreview, minimumState, previewType, announceMessageState, customerTypeState } = this.state;
		if (configuration) {
			const baseConfig = {
				...configuration,
				enabled: true,
				countdownStart: 48,
				hideAnimation: true,
				zindex: 2147480000,
				position: configuration.position === 'element' ? 'top' : configuration.position
			};
			if (previewType === 'Default' && configuration.backupType === 'custom' && announceMessageState !== 'auto') {
				const messageNumber = Number(announceMessageState.split('-')[1]);
				CUSTOM_BANNER_PROPERTIES.forEach(conf => {
					baseConfig[conf] = this.getConfigForMessageNumber(baseConfig, conf, messageNumber);
				});
				baseConfig.totalAnnouncements = 1;
			}
			const topBannerConfig = {
				...baseConfig,
				// These are overrides to make sure the banner doesn't look ridiculous when embedded
				fixed: false,
				position: 'element'
				// Old settings to put the banner floating at the top
				// position: 'top',
				// fixed: true
			};
			const mobileBannerConfig = {
				...baseConfig,
				fixed: false
			};
			if (this.bannerContainer.current && !this.banner) {
				this.banner = new Banner(null, {
					interactive: false,
					targetElement: this.bannerContainer.current
				});
			}
			if (this.mobileContainer.current && !this.mobileBanner) {
				this.mobileBanner = new Banner(null, {
					interactive: false,
					targetElement: this.mobileContainer.current
				});
			}
			const backupCode = configuration.backupType === 'discount' && configuration.backupCode && getPriceRule(configuration.backupCode);
			const shouldDisplayEndsAt = previewType === 'Directed' || configuration.customEndsAt || (backupCode && backupCode.endsAt);
			const discountConfig = {
				amount: 15,
				type: previewType === 'Default' ? configuration.backupType : discountPreview,
				code: EXAMPLE_CODE,
				subtotalMinimum: minimumState !== 'none' ? 50 : 0,
				startsAt: subDays(new Date(), 1).getTime(),
				endsAt: shouldDisplayEndsAt ? endsAt : null,
				products: [1, 2, 3, 4, 5, 6].map(i => createProduct(i))
			};
			if (discountConfig.type === 'freeShipping') {
				discountConfig.subtotalMinimum = configuration.customFreeShippingMinimum;
				discountConfig.products = null;
			}
			if (discountConfig.type === 'custom') {
				discountConfig.products = null;
			}
			let bannerTier = 0;
			let bannerTiers = [];
			let cartTotalPrice =
				minimumState === 'initial' ? 0 : minimumState === 'reached' ? discountConfig.subtotalMinimum : 0.5 * discountConfig.subtotalMinimum;
			if (previewType === 'Default' && configuration.backupType === 'discount' && backupCode) {
				let codeToUse = backupCode;
				if (configuration.backupCodeMessageType === 'tiered') {
					const getTieredPriceRule = tier => {
						const tierSuffix = Number(tier) === 0 ? '' : tier;
						const tierDiscountCode = configuration[`backupCode${tierSuffix}`];
						const tierCode = getPriceRule(tierDiscountCode);
						if (tierCode) {
							return {
								...tierCode,
								amount: configuration[`tierAmount${tierSuffix}`] || tierCode.amount,
								subtotalMinimum: configuration[`tierMinimum${tierSuffix}`] || tierCode.subtotalMinimum
							};
						} else {
							return {
								...backupCode,
								code: tierDiscountCode,
								amount: configuration[`tierAmount${tierSuffix}`],
								subtotalMinimum: configuration[`tierMinimum${tierSuffix}`]
							};
						}
					};
					let earnedCode = null;
					if (minimumState.indexOf('tier_') === 0) {
						const tier = Number(minimumState.split('tier_')[1]);
						bannerTier = tier - 1;
						codeToUse = getTieredPriceRule(bannerTier);
						if (codeToUse) {
							cartTotalPrice = codeToUse.subtotalMinimum - 1;
						}
						if (bannerTier > 0) {
							earnedCode = getTieredPriceRule(bannerTier - 1);
						}
					} else if (minimumState === 'reached') {
						codeToUse = getTieredPriceRule(configuration.totalTiers);
						earnedCode = codeToUse;
						bannerTier = configuration.totalTiers;
					}
					if (earnedCode) {
						discountConfig.earnedCode = earnedCode.code;
						discountConfig.earnedAmount = earnedCode.amount;
					}
					for (let i = 0; i <= configuration.totalTiers; i++) {
						bannerTiers.push(getTieredPriceRule(i));
					}
				}
				if (codeToUse) {
					if (minimumState === 'reached') {
						cartTotalPrice = codeToUse.subtotalMinimum;
					}
					discountConfig.type = 'discount';
					discountConfig.amount = codeToUse.amount;
					discountConfig.subtotalMinimum = codeToUse.subtotalMinimum;
					discountConfig.products = codeToUse.products;
					discountConfig.code = codeToUse.code;
				}
			}
			const discountConfiguration = new DiscountConfiguration(discountConfig);
			const customerJustSubscribed = customerTypeState === 'subscribed';
			this.banner.load(
				new Configuration(topBannerConfig),
				discountConfiguration,
				cartTotalPrice,
				bannerTier,
				bannerTiers,
				false,
				customerJustSubscribed,
				false
			);
			if (this.mobileBanner) {
				this.mobileBanner.load(
					new Configuration(mobileBannerConfig),
					discountConfiguration,
					cartTotalPrice,
					bannerTier,
					bannerTiers,
					false,
					customerJustSubscribed,
					false
				);
			}
		}
	}

	onPreviewTypeClicked = () => {
		this.setState(state => ({
			previewTypePopover: !state.previewTypePopover
		}));
	};

	onPreviewTypeClosed = () => {
		this.setState({
			previewTypePopover: false
		});
	};

	onMinimumClicked = () => {
		this.setState(state => ({
			minimumPopover: !state.minimumPopover
		}));
	};

	onMinimumClosed = () => {
		this.setState({
			minimumPopover: false
		});
	};

	onDiscountPreviewClicked = () => {
		this.setState(state => ({
			discountPreviewPopover: !state.discountPreviewPopover
		}));
	};

	onDiscountPreviewClosed = () => {
		this.setState({
			discountPreviewPopover: false
		});
	};

	onAnnounceMessageClicked = () => {
		this.setState(state => ({
			announcementMessagePopover: !state.announcementMessagePopover
		}));
	};

	onAnnounceMessageClosed = () => {
		this.setState({
			announcementMessagePopover: false
		});
	};

	setDiscountPreview = discountPreview => {
		this.setState({
			discountPreview,
			discountPreviewPopover: false
		});
	};

	setMinimumState = minimumState => {
		this.setState({
			minimumState,
			minimumPopover: false
		});
	};

	setAnnounceMessageState = announceMessageState => {
		this.setState({
			announceMessageState,
			announcementMessagePopover: false
		});
	};

	setPreviewType = previewType => {
		this.setState({
			previewType,
			minimumState: previewType === 'Directed' ? 'none' : 'initial',
			previewTypePopover: false
		});
	};

	toggleMobileSelected = () => {
		this.setState(state => {
			if (this.mobileBanner) {
				this.mobileBanner.destroy();
				this.mobileBanner = null;
			}
			return {
				mobileSelected: !state.mobileSelected
			};
		});
	};

	changeMobileOrientation = value => {
		this.setState({
			mobileOrientation: value
		});
	};

	changeMobileSize = value => {
		this.setState({
			mobileSize: value
		});
	};

	getMinimumTypes() {
		const { configuration } = this.props;
		const { previewType } = this.state;
		if (previewType === 'Directed') {
			return MINIMUM_TYPES;
		} else if (configuration.backupEnabled && configuration.backupType === 'discount' && configuration.backupCodeMessageType === 'tiered') {
			return Object.keys(TIERED_MINIMUM_TYPES)
				.filter(key => key.indexOf('tier_') !== 0 || Number(key.split('tier_')[1]) <= configuration.totalTiers + 1)
				.reduce((acc, curr) => ({ ...acc, [curr]: TIERED_MINIMUM_TYPES[curr] }), {});
		}
		return Object.keys(MINIMUM_TYPES)
			.filter(key => key !== 'none')
			.reduce((acc, curr) => ({ ...acc, [curr]: MINIMUM_TYPES[curr] }), {});
	}

	getAnnounceMessageTypes() {
		const {
			configuration: { totalAnnouncements }
		} = this.props;
		const messageTypes = {
			auto: 'Loop'
		};
		for (let i = 0; i < totalAnnouncements; i++) {
			const messageNumber = i + 1;
			messageTypes[`message-${messageNumber}`] = `Message ${messageNumber}`;
		}
		return messageTypes;
	}

	configurationHasEmailCapture() {
		const { configuration } = this.props;
		const { announceMessageState } = this.state;
		if (configuration.backupType !== 'custom') {
			return false;
		}
		if (announceMessageState === 'auto') {
			for (let i = 0; i < configuration.totalAnnouncements; i++) {
				if (this.getConfigForMessageNumber(configuration, 'actionType', i + 1) === 'email_capture') {
					return true;
				}
			}
		} else {
			const messageNumber = Number(announceMessageState.split('-')[1]);
			return this.getConfigForMessageNumber(configuration, 'actionType', messageNumber) === 'email_capture';
		}
		return false;
	}

	setCustomerTypeState = customerTypeState => {
		this.setState({
			customerTypeState
		});
		this.onHideCustomerTypePopover();
	};

	onShowCustomerTypePopover = () => {
		this.setState({
			showCustomerTypePopover: true
		});
	};

	onHideCustomerTypePopover = () => {
		this.setState({
			showCustomerTypePopover: false
		});
	};

	render() {
		const { configuration, getPriceRule, bannerCurrentlyShowing } = this.props;
		const {
			discountPreview,
			previewType,
			minimumState,
			discountPreviewPopover,
			// previewTypePopover,
			minimumPopover,
			announcementMessagePopover,
			announceMessageState,
			mobileSelected,
			mobileOrientation,
			mobileSize,
			customerTypeState,
			showCustomerTypePopover
		} = this.state;
		const noneSelected = !configuration.backupEnabled && previewType === 'Default';
		const { width: mobileWidth, height: mobileHeight } = MOBILE_SIZES[mobileSize];
		const discountPreviewItems = Object.keys(DISCOUNT_TYPES).map(key => ({
			content: DISCOUNT_TYPES[key],
			onAction: () => this.setDiscountPreview(key)
		}));
		const backupCode = configuration.backupType === 'discount' && configuration.backupCode && getPriceRule(configuration.backupCode);
		const minimumVisible =
			previewType === 'Directed' ||
			configuration.backupType === 'freeShipping' ||
			(configuration.backupCodeMessageType !== 'singular' && backupCode && backupCode.subtotalMinimum > 0);
		const minimumTypes = this.getMinimumTypes();
		const minimumItems = Object.keys(minimumTypes).map(key => ({
			content: minimumTypes[key],
			onAction: () => this.setMinimumState(key)
		}));
		const announceMessageTypes = this.getAnnounceMessageTypes();
		const announceMessageItems = Object.keys(announceMessageTypes).map(key => ({
			content: announceMessageTypes[key],
			onAction: () => this.setAnnounceMessageState(key)
		}));

		return (
			<Stack spacing="loose" vertical>
				<StickyContainer>
					<BannerPreviewContainer>
						<Card>
							<Card.Header title="Preview">
								{!noneSelected &&
									previewType === 'Default' &&
									configuration.backupType === 'custom' &&
									configuration.totalAnnouncements > 1 && (
										<Popover
											activator={
												<Button onClick={this.onAnnounceMessageClicked} variant="plain" disclosure>
													{announceMessageTypes[announceMessageState]}
												</Button>
											}
											active={announcementMessagePopover}
											onClose={this.onAnnounceMessageClosed}
											autofocusTarget="none">
											<Popover.Pane>
												<ActionList items={announceMessageItems} />
											</Popover.Pane>
										</Popover>
									)}
								{!noneSelected && previewType === 'Default' && this.configurationHasEmailCapture() && (
									<Popover
										activator={
											<Button onClick={this.onShowCustomerTypePopover} variant="plain" disclosure>
												{CUSTOMER_STATE_TYPES[customerTypeState]}
											</Button>
										}
										active={showCustomerTypePopover}
										onClose={this.onHideCustomerTypePopover}
										autofocusTarget="none">
										<Popover.Pane>
											<ActionList
												items={Object.keys(CUSTOMER_STATE_TYPES).map(stateType => ({
													content: CUSTOMER_STATE_TYPES[stateType],
													onAction: () => this.setCustomerTypeState(stateType)
												}))}
											/>
										</Popover.Pane>
									</Popover>
								)}
								{!noneSelected && previewType === 'Directed' && (
									<Popover
										activator={
											<Button onClick={this.onDiscountPreviewClicked} variant="plain" disclosure>
												{DISCOUNT_TYPES[discountPreview]}
											</Button>
										}
										active={discountPreviewPopover}
										onClose={this.onDiscountPreviewClosed}
										autofocusTarget="none">
										<Popover.Pane>
											<ActionList items={discountPreviewItems} />
										</Popover.Pane>
									</Popover>
								)}
								{!noneSelected && minimumVisible && (
									<Popover
										activator={
											<Button onClick={this.onMinimumClicked} variant="plain" disclosure>
												{minimumTypes[minimumState]}
											</Button>
										}
										active={minimumPopover}
										onClose={this.onMinimumClosed}
										autofocusTarget="none">
										<Popover.Pane>
											<ActionList items={minimumItems} />
										</Popover.Pane>
									</Popover>
								)}
								{/* <SelectableIcon source={MobileIcon} tooltip="Mobile preview" selected={mobileSelected} onClick={this.toggleMobileSelected} /> */}
							</Card.Header>
							<Card.Section>
								{noneSelected && (
									<Stack alignment="center" distribution="center">
										<p>{bannerCurrentlyShowing ? 'No banner is showing on your storefront.' : 'No banner will show on your storefront.'}</p>
									</Stack>
								)}
								{!noneSelected && <div ref={this.bannerContainer}></div>}
							</Card.Section>
						</Card>
					</BannerPreviewContainer>
				</StickyContainer>
				{mobileSelected && (
					<Card title="Mobile preview" sectioned>
						<Stack vertical>
							<p>
								Adjust the device and orientation to see what the banner looks like in a variety of mobile situations. The mobile preview is
								scaled to half the actual size.
							</p>
							<FormLayout>
								<FormLayout.Group>
									<Select
										label="Orientation"
										value={mobileOrientation}
										onChange={this.changeMobileOrientation}
										options={Object.keys(MOBILE_ORIENTATIONS).map(orientation => ({
											value: orientation,
											label: MOBILE_ORIENTATIONS[orientation]
										}))}
									/>
									<Select label="Device" value={mobileSize} onChange={this.changeMobileSize} options={Object.keys(MOBILE_SIZES)} />
								</FormLayout.Group>
							</FormLayout>
							<MobileOutlineContainer height={mobileOrientation === 'portrait' ? mobileHeight : mobileWidth}>
								<MobileOutline
									ref={this.mobileContainer}
									width={mobileOrientation === 'portrait' ? mobileWidth : mobileHeight}
									height={mobileOrientation === 'portrait' ? mobileHeight : mobileWidth}
								/>
							</MobileOutlineContainer>
						</Stack>
					</Card>
				)}
			</Stack>
		);
	}
}

const BannerPreviewContainer = styled.div`
	& > div > div {
		z-index: var(--p-z-index-2);
	}
`;

const MobileOutlineContainer = styled.div`
	height: ${({ height }) => `${height * MOBILE_SCALE}px`};
`;

const MobileOutline = styled.div`
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 50%;
	border: solid 2px #000;
	overflow: hidden;
	transform: scale(${MOBILE_SCALE}) translateX(-50%);
	transform-origin: 0 0;
	width: ${({ width }) => `${width}px`};
	height: ${({ height }) => `${height}px`};
`;

const StickyContainer = styled.div`
	position: sticky;
	top: 0;
`;
