import { useLocalStorage } from '@segunosoftware/equinox';
import { useCallback, useEffect } from 'react';

const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

export function useLocalDismiss(key: string, expirationDays?: number) {
	const [hidden, setHidden] = useLocalStorage<number | null>(key, null);

	useEffect(() => {
		if (hidden === null || !expirationDays) {
			return;
		}
		if (new Date().getTime() - new Date(hidden).getTime() >= MILLIS_PER_DAY * expirationDays) {
			setHidden(null);
		}
	}, [hidden, setHidden, expirationDays]);

	const onDismiss = useCallback(() => setHidden(new Date().getTime()), [setHidden]);

	return [hidden !== null, onDismiss] as const;
}
