import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { VERIFY_INSTALLATION } from './query-keys';
import type { Get } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

const THEME_APP_EXTENSION_UUID = import.meta.env.VITE_THEME_APP_EXTENSION_UUID;

type VerifiedInstallResponse = {
	verified: boolean;
};

export function useVerifyInstallation(shop: string) {
	const { get } = useAuthenticatedFetch() as Get<VerifiedInstallResponse>;
	const { data, isFetching: isLoading } = useQuery({
		queryKey: queryKey(VERIFY_INSTALLATION),
		queryFn: () => get('/account/verify-install')
	});
	const turnOnAppEmbedUrl = `https://${shop}/admin/themes/current/editor?context=apps&activateAppId=${THEME_APP_EXTENSION_UUID}/banners-app-embed`;

	return { verified: data?.verified ?? false, isLoading, turnOnAppEmbedUrl };
}
