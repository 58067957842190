import { useState } from 'react';
import Settings from '../components/Settings';
import { useAccount } from '../hooks/useAccount';
import { useGetBannerConfiguration, useOnSaveBannerConfiguration } from '../hooks/useBannerConfiguration';
import { useGetDefaultPriceRuleId } from '../hooks/useExternal';
import { useGetPriceRule, useGetPriceRuleById, useLoadPriceRule, useLoadPriceRuleById } from '../hooks/usePriceRules';

export default function SettingsContainer() {
	const account = useAccount();
	const [queryConfig, setQueryConfig] = useState({
		code: null,
		cdnCache: null
	});

	const [queryId, setQueryId] = useState(null);

	const externalPriceRuleId = useGetDefaultPriceRuleId();

	const { configuration, isBannerConfigurationLoading } = useGetBannerConfiguration();
	const { onSaveBannerConfiguration, isBannerConfigurationSaving } = useOnSaveBannerConfiguration();

	const { isPriceRuleByIdLoading } = useLoadPriceRuleById(queryId);
	const { isPriceRuleLoading } = useLoadPriceRule(queryConfig);

	const getPriceRule = useGetPriceRule();
	const getPriceRuleById = useGetPriceRuleById();

	return (
		<Settings
			getPriceRule={getPriceRule}
			getPriceRuleById={getPriceRuleById}
			getPriceRuleError={code => getErrorMessage(getPriceRule(code))}
			isPriceRuleLoading={() => isPriceRuleLoading}
			isPriceRuleLoadingById={() => isPriceRuleByIdLoading}
			onLoadPriceRule={(code, cdnCache) => setQueryConfig({ code, cdnCache })}
			onLoadPriceRuleById={id => setQueryId(id)}
			account={account}
			configuration={configuration}
			isBannerConfigurationLoading={isBannerConfigurationLoading}
			isBannerConfigurationSaving={isBannerConfigurationSaving}
			onSaveBannerConfiguration={configuration => onSaveBannerConfiguration(configuration)}
			isLoading={isBannerConfigurationLoading || isBannerConfigurationSaving || isPriceRuleLoading}
			externalPriceRuleId={externalPriceRuleId}
		/>
	);
}

const getErrorMessage = priceRule => {
	if (priceRule) {
		if (!priceRule?.priceRuleConfiguration) {
			return 'This discount was not found in Shopify.';
		}
		const now = new Date();
		if (priceRule?.priceRuleConfiguration?.endsAt && new Date(priceRule.priceRuleConfiguration?.endsAt).getTime() <= now.getTime()) {
			return 'This discount has expired.';
		}
	}
	return null;
};
