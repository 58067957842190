import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { CACHED_ACCOUNT, USER } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type ReviewSentiment = 'happy' | 'sad' | 'unspecified';
export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';
export type DisplayAdStatus = 'ignored' | 'acknowledged';

export type Account = {
	id: number;
	shop: string;
	domain: string;
	timezone: string;
	currency: string;
	platformPlan: string;
	ownerName: string;
	ownerEmail: string;
	userHash: string;
	supportUserHash: string;
	phone: string;
	name: string;
	billingStatus: AccountBillingStatus;
	reviewSentiment: ReviewSentiment;
	reviewStatus: ReviewStatus;
	storefrontProtected: boolean;
	displayAdStatus: DisplayAdStatus;
	reviewRespondedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

export const useReviewSentiment = () => {
	const { post } = useAuthenticatedFetch() as Post<ReviewSentiment, void>;
	const { mutate } = useMutation({
		mutationFn: (payload: ReviewSentiment) => post('/account/review-sentiment', payload)
	});
	return mutate;
};

export const useReviewStatus = () => {
	const { post } = useAuthenticatedFetch() as Post<ReviewStatus, void>;
	const { mutate } = useMutation({
		mutationFn: (payload: ReviewStatus) => post('/account/review-status', payload)
	});
	return mutate;
};

export function useAccountFetch(load = false) {
	const client = useQueryClient();
	const { get } = useAuthenticatedFetch() as Get<Account>;
	const { data: account, isLoading } = useQuery({
		queryKey: queryKey(USER),
		queryFn: () => get('/auth/whoami'),
		select: useDateTransformerFactory('reviewRespondedAt'),
		enabled: load
	});
	useEffect(() => {
		const cachedAccount = client.getQueryData(queryKey(CACHED_ACCOUNT));
		if (cachedAccount === null && !isLoading && account) {
			client.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
		}
	}, [account, isLoading, client]);
	return { account, isLoading };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useCachedAccount() {
	const client = useQueryClient();
	const { data: account } = useQuery({
		queryKey: queryKey(CACHED_ACCOUNT),
		queryFn: () => Promise.resolve(client.getQueryData<Account>(queryKey(USER)) || null)
	});
	return account;
}
