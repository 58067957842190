import { Stack } from '@segunosoftware/equinox';
import { Button, Popover } from '@shopify/polaris';
import { useState } from 'react';

export type MessageVariablesProps = {
	showAmountAndCode?: boolean;
	showRemaining?: boolean;
	showMinimum?: boolean;
	showEarned?: boolean;
};

export default function MessageVariables({
	showAmountAndCode = true,
	showRemaining = true,
	showMinimum = true,
	showEarned = false
}: MessageVariablesProps) {
	const [open, setOpen] = useState(false);

	return (
		<Stack distribution="leading">
			<Popover
				activator={
					<Button onClick={() => setOpen(true)} variant="plain">
						View message variables
					</Button>
				}
				onClose={() => setOpen(false)}
				preferredPosition="above"
				active={open}
				sectioned>
				<p>{showAmountAndCode && '{code} = The discount code.'}</p>
				<p>{showAmountAndCode && '{amount} = The value of the discount.'}</p>
				<p>
					{showMinimum && `{minimum} = ${!showAmountAndCode ? 'The minimum order price.' : 'The minimum purchase value of the discount.'}`}
				</p>
				<p>{showRemaining && '{remaining} = The cart value still needed to reach the minimum.'}</p>
				<p>{showEarned && '{earned-code} = The discount code for the earned tiered.'}</p>
				<p>{showEarned && '{earned-amount} = The value of the discount for the earned tiered.'}</p>
			</Popover>
		</Stack>
	);
}
