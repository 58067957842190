import { Card, DateTimePicker, Stack, Subheading } from '@segunosoftware/equinox';
import { Checkbox, ChoiceList, FormLayout, Layout, Link, Select, TextField } from '@shopify/polaris';
import { endOfDay, format } from 'date-fns';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReviewBannerContainer from '../containers/ReviewBannerContainer';
import { useLocalDismiss } from '../hooks/useLocalDismiss';
import AppEmbedWarningBanner from './AppEmbedWarningBanner';
import AskForHelp from './AskForHelp';
import ColorPicker from './ColorPicker';
import CustomBanner from './CustomBanner';
import DiscountBanner from './DiscountBanner';
import FontPicker from './FontPicker';
import FreeShippingBanner from './FreeShippingBanner';
import OnboardingCard from './OnboardingCard';
import SelectButtonContainer from './SelectButtonContainer';
import { activateMessage } from './SupportChat';

const PROMOS_HIDDEN_KEY = '__seguno_banner_promos_hidden';
const ONBOARDING_DISMISS = '__seguno_banner_onboarding_hidden';

const SIZES = {
	tiny: 'Tiny',
	small: 'Small',
	medium: 'Medium',
	large: 'Large'
};

const DATE_TIME_FORMAT = "h:mmaaaaa'm' 'on' MMM d, yyyy";

export default function BannerConfigurationForm(props) {
	const [hidden, onDismiss] = useLocalDismiss(PROMOS_HIDDEN_KEY);
	const [onboardingHidden, onDismissOnboarding] = useLocalDismiss(ONBOARDING_DISMISS);

	return (
		<BannerConfigurationFormInternal
			{...props}
			onDismissAd={onDismiss}
			adDismissed={hidden}
			onDismissOnboarding={onDismissOnboarding}
			onboardingDismissed={onboardingHidden}
		/>
	);
}

class BannerConfigurationFormInternal extends Component {
	static propTypes = {
		onConfigurationChanged: PropTypes.func.isRequired,
		account: PropTypes.shape({
			domain: PropTypes.string.isRequired,
			billingStatus: PropTypes.string.isRequired,
			billingExternalId: PropTypes.string,
			timezone: PropTypes.string.isRequired,
			currency: PropTypes.string.isRequired,
			reviewSentiment: PropTypes.oneOf(['happy', 'sad', 'unspecified']).isRequired,
			reviewStatus: PropTypes.oneOf(['ignored', 'reviewed', 'denied', 'deferred']).isRequired,
			displayAdStatus: PropTypes.oneOf(['ignored', 'acknowledged']).isRequired
		}).isRequired,
		configuration: PropTypes.shape({
			enabled: PropTypes.bool.isRequired,
			position: PropTypes.oneOf(['top', 'bottom', 'element']).isRequired,
			targetElement: PropTypes.string,
			fontType: PropTypes.string.isRequired,
			fontFamily: PropTypes.string.isRequired,
			background: PropTypes.string.isRequired,
			foreground: PropTypes.string.isRequired,
			size: PropTypes.oneOf(Object.keys(SIZES)).isRequired,
			countdownStart: PropTypes.number.isRequired,
			fixed: PropTypes.bool.isRequired,
			displayProducts: PropTypes.bool.isRequired,
			displayCountdown: PropTypes.bool.isRequired,
			displayProductTitles: PropTypes.bool.isRequired,
			countdownPosition: PropTypes.oneOf(['left', 'right']).isRequired,
			countdownFontType: PropTypes.string.isRequired,
			countdownFontFamily: PropTypes.string.isRequired,
			countdownBackground: PropTypes.string.isRequired,
			countdownForeground: PropTypes.string.isRequired,
			labelFixedAmount: PropTypes.string.isRequired,
			labelPercentage: PropTypes.string.isRequired,
			labelShipping: PropTypes.string.isRequired,
			backupEnabled: PropTypes.bool.isRequired,
			backupType: PropTypes.oneOf(['discount', 'custom', 'freeShipping']).isRequired,
			actionType: PropTypes.oneOf(['none', 'link_banner', 'link_button', 'email_capture']).isRequired,
			actionBackground: PropTypes.string.isRequired,
			actionForeground: PropTypes.string.isRequired,
			actionNewTab: PropTypes.bool.isRequired,
			customLabel: PropTypes.string,
			customStartsAt: PropTypes.instanceOf(Date),
			customEndsAt: PropTypes.instanceOf(Date),
			actionUrl: PropTypes.string,
			actionLabel: PropTypes.string,
			backupCode: PropTypes.string,
			totalAnnouncements: PropTypes.number.isRequired,
			announcementTimer: PropTypes.number.isRequired,
			hideAnimation: PropTypes.bool.isRequired,
			disableAutoApply: PropTypes.bool.isRequired,
			customFreeShippingMinimum: PropTypes.number.isRequired,
			customLabelFreeShippingWithMinimum: PropTypes.string.isRequired,
			customLabelFreeShippingWithMinimumRemaining: PropTypes.string.isRequired,
			customLabelFreeShippingWithMinimumReached: PropTypes.string.isRequired,
			customLabelDiscount: PropTypes.string,
			customLabelDiscountWithMinimum: PropTypes.string,
			customLabelDiscountWithMinimumRemaining: PropTypes.string,
			customLabelDiscountWithMinimumReached: PropTypes.string,
			customDisplayCountdown: PropTypes.bool.isRequired,
			customDisplayProducts: PropTypes.bool.isRequired
		}),
		getPriceRule: PropTypes.func.isRequired,
		getPriceRuleError: PropTypes.func.isRequired,
		isPriceRuleLoading: PropTypes.func.isRequired,
		onDismissAd: PropTypes.func.isRequired,
		adDismissed: PropTypes.bool.isRequired,
		onDismissOnboarding: PropTypes.func.isRequired,
		onboardingDismissed: PropTypes.bool.isRequired
	};

	static defaultProps = {
		configuration: null
	};

	state = {
		countdownSelect: 'days'
	};

	configurationChanged = (value, id) => {
		this.configurationsChanged({
			[id]: value
		});
	};

	configurationsChanged = values => {
		const { configuration, onConfigurationChanged } = this.props;
		const newConfig = { ...configuration };
		Object.keys(values).forEach(id => {
			newConfig[id] = values[id];
		});
		onConfigurationChanged(newConfig);
	};

	positionChanged = value => {
		const [position, fixed] = value.split('-');
		this.configurationsChanged({
			position,
			fixed: fixed === 'true'
		});
	};

	countdownSelectChanged = value => {
		const {
			configuration: { countdownStart }
		} = this.props;
		this.configurationChanged(value === 'days' ? countdownStart * 24 : countdownStart / 24, 'countdownStart');
		this.setState({
			countdownSelect: value
		});
	};

	getCountdownStart() {
		const {
			configuration: { countdownStart }
		} = this.props;
		const { countdownSelect } = this.state;
		if (countdownSelect === 'hours') {
			return countdownStart;
		}
		return countdownStart / 24;
	}

	countdownStartChanged = value => {
		const { countdownSelect } = this.state;
		const flooredValue = Math.floor(value);
		const actualValue = countdownSelect === 'hours' ? flooredValue : flooredValue * 24;
		this.configurationChanged(actualValue, 'countdownStart');
	};

	colorPickerHandler = id => color => {
		const { red, green, blue, alpha = 1 } = color;
		this.configurationChanged(`rgba(${red}, ${green}, ${blue}, ${alpha})`, id);
	};

	fontChanged = (fontType, fontFamily) => {
		this.configurationsChanged({
			fontType,
			fontFamily
		});
	};

	countdownFontChanged = (fontType, fontFamily) => {
		this.configurationsChanged({
			countdownFontType: fontType,
			countdownFontFamily: fontFamily
		});
	};

	onSelectButtonClicked = selectedOption => {
		const configs = {};
		if (selectedOption === 'disabled') {
			configs['backupEnabled'] = false;
		} else {
			configs['backupEnabled'] = true;
			configs['backupType'] = selectedOption;
		}
		this.configurationsChanged(configs);
	};

	customStartDateSelectionChanged = selections => {
		const selection = selections[0];
		this.configurationChanged(selection ? null : new Date(), 'customStartsAt');
	};

	customEndDateSelectionChanged = selections => {
		const selection = selections[0];
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const endDate = configuration.backupType === 'discount' && backupCode && backupCode.endsAt ? backupCode.endsAt : endOfDay(new Date());
		this.configurationChanged(selection ? null : endDate, 'customEndsAt');
	};

	calculateMaxStartDate() {
		return this.calculateMaxEndDate();
	}

	calculateMinStartDate() {
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		return configuration.backupEnabled && configuration.backupType === 'discount' && backupCode && backupCode.startsAt
			? backupCode.startsAt
			: undefined;
	}

	calculateMaxEndDate() {
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		return configuration.backupEnabled && configuration.backupType === 'discount' && backupCode && backupCode.endsAt
			? backupCode.endsAt
			: undefined;
	}

	calculateMinEndDate() {
		const { configuration } = this.props;
		return configuration.customStartsAt;
	}

	onCustomStartsAtChanged = customStartsAt => {
		const { configuration } = this.props;
		const customEndsAt = configuration.customEndsAt;
		const values = {
			customStartsAt
		};
		if (customEndsAt && customEndsAt.getTime() < customStartsAt.getTime()) {
			values.customEndsAt = endOfDay(customStartsAt);
		}
		this.configurationsChanged(values);
	};

	onCustomEndsAtChanged = customEndsAt => {
		this.configurationChanged(customEndsAt, 'customEndsAt');
	};

	getDisplayImmediatelyLabel() {
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const now = new Date();
		if (configuration.backupType === 'discount' && backupCode && backupCode.startsAt.getTime() > now.getTime()) {
			return `Display when the discount becomes active at ${format(backupCode.startsAt, DATE_TIME_FORMAT)}`;
		}
		return 'Display immediately';
	}

	getDisplayIndefinitelyLabel() {
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		if (configuration.backupType === 'discount' && backupCode && backupCode.endsAt) {
			return `Display until discount expires at ${format(backupCode.endsAt, DATE_TIME_FORMAT)}`;
		}
		return 'Display indefinitely';
	}

	renderDesignCard() {
		const { configuration } = this.props;
		const { countdownSelect } = this.state;
		return (
			<Card title="Design">
				<Card.Section>
					<FormLayout>
						<ColorPicker label="Background color" color={configuration.background} onChange={this.colorPickerHandler('background')} />
						<ColorPicker label="Text color" color={configuration.foreground} onChange={this.colorPickerHandler('foreground')} />
						<FontPicker fontType={configuration.fontType} fontFamily={configuration.fontFamily} onFontChanged={this.fontChanged} />
						<ChoiceList
							title="Size"
							selected={[configuration.size]}
							onChange={selections => this.configurationChanged(selections[0], 'size')}
							choices={Object.keys(SIZES).map(size => ({ value: size, label: SIZES[size] }))}
						/>
						<ChoiceList
							title="Position"
							selected={[configuration.position + '-' + configuration.fixed]}
							onChange={selections => this.positionChanged(selections[0])}
							choices={[
								{ value: 'top-true', label: 'Stay floating at the top' },
								{ value: 'bottom-true', label: 'Stay floating at the bottom' },
								{ value: 'top-false', label: 'Start up top and scroll off the screen' },
								{ value: 'element-false', label: 'Insert into a specific location' }
							]}
						/>
						{configuration.position === 'element' && (
							<TextField
								label="Target element (CSS selector)"
								autoComplete="off"
								id="targetElement"
								helpText={
									<div>
										Contact us for <Link onClick={() => activateMessage('Help adjusting target element')}>help</Link> and we will suggest
										position settings for your theme.
									</div>
								}
								value={configuration.targetElement || ''}
								onChange={this.configurationChanged}
							/>
						)}
						{configuration.position === 'element' && (
							<ChoiceList
								title="Location related to target element"
								selected={[configuration.targetElementPosition]}
								onChange={selections => this.configurationChanged(selections[0], 'targetElementPosition')}
								choices={[
									{ value: 'before', label: 'Before' },
									{ value: 'top', label: 'Beginning' },
									{ value: 'bottom', label: 'End' },
									{ value: 'after', label: 'After' }
								]}
							/>
						)}
						{configuration.position === 'top' && !configuration.fixed && (
							<TextField
								label="Stack order (CSS z-index value)"
								id="zindex"
								type="number"
								autoComplete="off"
								min={0}
								max={2147483647}
								helpText={
									<div>
										Contact us for <Link onClick={() => activateMessage('Help adjusting z-index')}>help</Link>. Typically adjusted if mobile
										menu elements are covered.
									</div>
								}
								value={configuration.zindex.toString()}
								onChange={this.configurationChanged}
							/>
						)}
						<ChoiceList
							title="Animation"
							selected={[configuration.hideAnimation]}
							onChange={selections => this.configurationChanged(selections[0], 'hideAnimation')}
							choices={[
								{ value: true, label: 'None, just appear' },
								{ value: false, label: 'Slide in smoothly' }
							]}
						/>
						<Checkbox
							label="Allow banner to be dismissed"
							checked={configuration.closeType === 'dismiss'}
							onChange={newValue => this.configurationChanged(newValue ? 'dismiss' : 'hidden', 'closeType')}
						/>
					</FormLayout>
				</Card.Section>
				<Card.Section title="Product carousel">
					<Checkbox
						label="Overlay titles on product images"
						id="displayProductTitles"
						checked={configuration.displayProductTitles}
						onChange={this.configurationChanged}
					/>
				</Card.Section>
				<Card.Section title="Action button">
					<FormLayout>
						<ColorPicker
							label="Background color"
							color={configuration.actionBackground}
							onChange={this.colorPickerHandler('actionBackground')}
						/>
						<ColorPicker label="Text color" color={configuration.actionForeground} onChange={this.colorPickerHandler('actionForeground')} />
					</FormLayout>
				</Card.Section>
				<Card.Section title="Discount tiers progress bar">
					<FormLayout>
						<ColorPicker
							label="Bar color"
							color={configuration.tierProgressBarColor}
							onChange={this.colorPickerHandler('tierProgressBarColor')}
						/>
						<ColorPicker
							label="Progress color"
							color={configuration.tierProgressColor}
							onChange={this.colorPickerHandler('tierProgressColor')}
						/>
						<ColorPicker
							label="Tier color"
							color={configuration.tierProgressTierColor}
							onChange={this.colorPickerHandler('tierProgressTierColor')}
						/>
						<ColorPicker
							label="Icon color"
							color={configuration.tierProgressIconColor}
							onChange={this.colorPickerHandler('tierProgressIconColor')}
						/>
						<ColorPicker
							label="Icon reached color"
							color={configuration.tierProgressIconReachedColor}
							onChange={this.colorPickerHandler('tierProgressIconReachedColor')}
						/>
					</FormLayout>
				</Card.Section>
				<Card.Section title="Countdown timer">
					<FormLayout>
						<ColorPicker
							label="Background color"
							color={configuration.countdownBackground}
							onChange={this.colorPickerHandler('countdownBackground')}
						/>
						<ColorPicker
							label="Text color"
							color={configuration.countdownForeground}
							onChange={this.colorPickerHandler('countdownForeground')}
						/>
						<FontPicker
							fontType={configuration.countdownFontType}
							fontFamily={configuration.countdownFontFamily}
							onFontChanged={this.countdownFontChanged}
						/>
						<ChoiceList
							title="Position"
							selected={[configuration.countdownPosition]}
							onChange={selections => this.configurationChanged(selections[0], 'countdownPosition')}
							choices={[
								{ value: 'left', label: 'Left' },
								{ value: 'right', label: 'Right' }
							]}
						/>
						<TextField
							type="number"
							label="Start countdown ahead of end date by"
							helpText="Enter 0 to display immediately."
							autoComplete="off"
							min={0}
							value={this.getCountdownStart().toString()}
							onChange={this.countdownStartChanged}
							connectedRight={
								<Select
									label="Countdown unit"
									labelHidden
									options={['days', 'hours']}
									value={countdownSelect}
									onChange={this.countdownSelectChanged}
								/>
							}
						/>
						<Subheading>Time unit abbreviations</Subheading>
						<FormLayout.Group condensed>
							<TextField
								label="Days"
								autoComplete="off"
								id="countdownDayLabel"
								value={configuration.countdownDayLabel}
								placeholder="DAY"
								onChange={this.configurationChanged}
							/>
							<TextField
								label="Hours"
								autoComplete="off"
								id="countdownHourLabel"
								value={configuration.countdownHourLabel}
								placeholder="HR"
								onChange={this.configurationChanged}
							/>
							<TextField
								label="Minutes"
								autoComplete="off"
								id="countdownMinuteLabel"
								value={configuration.countdownMinuteLabel}
								placeholder="MIN"
								onChange={this.configurationChanged}
							/>
							<TextField
								label="Seconds"
								autoComplete="off"
								id="countdownSecondLabel"
								value={configuration.countdownSecondLabel}
								placeholder="SEC"
								onChange={this.configurationChanged}
							/>
						</FormLayout.Group>
					</FormLayout>
				</Card.Section>
			</Card>
		);
	}

	render() {
		const {
			account,
			configuration,
			getPriceRule,
			getPriceRuleError,
			isPriceRuleLoading,
			// onDismissAd,
			// adDismissed,
			onboardingDismissed,
			onDismissOnboarding
		} = this.props;
		const isLoadingBackupCode = isPriceRuleLoading(configuration.backupCode);
		const backupCode = getPriceRule(configuration.backupCode);
		const backupCodeError = getPriceRuleError(configuration.backupCode);
		const showCountdownTimerOption =
			configuration.customEndsAt || (configuration.backupType === 'discount' && backupCode && backupCode.endsAt);
		const isPreviewVisible = configuration.backupEnabled;
		const showActiveDates =
			configuration.backupEnabled && (configuration.backupType !== 'discount' || (!isLoadingBackupCode && backupCode && !backupCodeError));
		return (
			<Layout>
				<Layout.Section>
					<Stack spacing="loose" vertical>
						<Layout>
							<AppEmbedWarningBanner shop={account.shop} usingScriptTag={account.usingScriptTag} />
							<ReviewBannerContainer />
							<Layout.Section>
								<Card title="Active banner">
									<Card.Section>
										<Stack vertical>
											<SelectButtonContainer configuration={configuration} onClick={this.onSelectButtonClicked} />
											{configuration.backupEnabled && configuration.backupType === 'custom' && (
												<CustomBanner
													configuration={configuration}
													onChange={this.configurationChanged}
													onMultiChange={this.configurationsChanged}
													countdownAvailable={
														showCountdownTimerOption && configuration.customDisplayCountdown && configuration.totalAnnouncements > 1
													}
												/>
											)}
											{configuration.backupEnabled && configuration.backupType === 'freeShipping' && (
												<FreeShippingBanner
													configuration={configuration}
													onChange={this.configurationChanged}
													currency={account.currency}
												/>
											)}
											{configuration.backupEnabled && configuration.backupType === 'discount' && (
												<DiscountBanner
													account={account}
													configuration={configuration}
													onChange={this.configurationChanged}
													onMultiChange={this.configurationsChanged}
													currency={account.currency}
													getPriceRule={getPriceRule}
													isPriceRuleLoading={isPriceRuleLoading}
													getPriceRuleError={getPriceRuleError}
												/>
											)}
										</Stack>
									</Card.Section>
									{showActiveDates && (
										<Card.Section title="Active dates">
											<FormLayout>
												<ChoiceList
													title="Start date"
													choices={[
														{ label: this.getDisplayImmediatelyLabel(), value: true },
														{ label: 'Schedule', value: false }
													]}
													selected={[!configuration.customStartsAt]}
													onChange={this.customStartDateSelectionChanged}
												/>
												{configuration.customStartsAt && (
													<DateTimePicker
														dateLabel="Start date"
														timeLabel="Start time"
														timezone={account.timezone}
														disableDatesBefore={this.calculateMinStartDate()}
														disableDatesAfter={this.calculateMaxStartDate()}
														onChange={this.onCustomStartsAtChanged}
														value={configuration.customStartsAt}
													/>
												)}
												<ChoiceList
													title="End date"
													choices={[
														{ label: this.getDisplayIndefinitelyLabel(), value: true },
														{ label: 'Schedule', value: false }
													]}
													selected={[!configuration.customEndsAt]}
													onChange={this.customEndDateSelectionChanged}
												/>
												{configuration.customEndsAt && (
													<DateTimePicker
														dateLabel="End date"
														timeLabel="End time"
														timezone={account.timezone}
														disableDatesBefore={this.calculateMinEndDate()}
														disableDatesAfter={this.calculateMaxEndDate()}
														onChange={this.onCustomEndsAtChanged}
														value={configuration.customEndsAt}
													/>
												)}
												{showCountdownTimerOption && (
													<Checkbox
														label="Show countdown timer"
														id="customDisplayCountdown"
														checked={configuration.customDisplayCountdown}
														helpText={configuration.totalAnnouncements > 1 ? 'Can be hidden for individual messages above.' : undefined}
														onChange={this.configurationChanged}
													/>
												)}
											</FormLayout>
										</Card.Section>
									)}
								</Card>
							</Layout.Section>
							{!isPreviewVisible && !onboardingDismissed && (
								<Layout.Section>
									<OnboardingCard shop={account.shop} onDismiss={onDismissOnboarding} />
								</Layout.Section>
							)}
							<Layout.Section>
								<AskForHelp />
							</Layout.Section>
						</Layout>
					</Stack>
				</Layout.Section>
				<Layout.Section variant="oneThird">
					<Layout sectioned>
						{/* {!adDismissed && <AdCarousel onDismiss={onDismissAd} />} */}
						{isPreviewVisible && this.renderDesignCard()}
						{!isPreviewVisible && (
							<Card title="Design" sectioned>
								<p>Customization settings will appear after selecting a banner type.</p>
							</Card>
						)}
					</Layout>
				</Layout.Section>
			</Layout>
		);
	}
}
