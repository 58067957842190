import { ContextualSaveBar, Loading, LoadingScreen, Stack, useToast } from '@segunosoftware/equinox';
import { Banner, Layout, List, Page } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import BannerConfigurationForm from './BannerConfigurationForm';
import BannerPreview from './BannerPreview';
import { activateSupport } from './SupportChat';

const LOAD_BACKUP_DEBOUNCE = 1000;

const isEmpty = str => !str || str.toString().trim() === '';

const isValidUrl = url => {
	const input = document.createElement('input');
	input.type = 'url';
	input.value = url;
	try {
		return input.checkValidity();
	} catch (e) {
		return true;
	}
};

export default function Settings({ ...props }) {
	const showToast = useToast();
	return <SettingsInternal {...props} showToast={showToast} />;
}

class SettingsInternal extends Component {
	static propTypes = {
		onSaveBannerConfiguration: PropTypes.func.isRequired,
		onLoadPriceRule: PropTypes.func.isRequired,
		onLoadPriceRuleById: PropTypes.func.isRequired,
		getPriceRule: PropTypes.func.isRequired,
		getPriceRuleById: PropTypes.func.isRequired,
		account: PropTypes.object.isRequired,
		getPriceRuleError: PropTypes.func.isRequired,
		configuration: PropTypes.object,
		isPriceRuleLoading: PropTypes.func.isRequired,
		isPriceRuleLoadingById: PropTypes.func.isRequired,
		isBannerConfigurationSaving: PropTypes.bool,
		isBannerConfigurationLoading: PropTypes.bool,
		externalPriceRuleId: PropTypes.string,
		isLoading: PropTypes.bool.isRequired
	};

	static defaultProps = {
		configuration: null,
		isBannerConfigurationSaving: false,
		isBannerConfigurationLoading: false,
		externalPriceRuleId: null
	};

	state = {
		configuration: null,
		appliedExternalPriceRule: false,
		dirty: false,
		errors: []
	};

	componentDidMount() {
		this.initializeStateFromProps(true);
	}

	componentWillUnmount() {
		clearTimeout(this.loadBackupCodeTimeout);
	}

	componentDidUpdate(prevProps, prevState) {
		const { isBannerConfigurationSaving, showToast } = this.props;
		if (isBannerConfigurationSaving && isBannerConfigurationSaving !== prevProps.isBannerConfigurationSaving) {
			this.setState({
				dirty: false
			});
		}
		if (!isBannerConfigurationSaving && prevProps.isBannerConfigurationSaving) {
			showToast('Settings saved');
		}
		if (!isBannerConfigurationSaving) {
			this.initializeStateFromProps();
		}
	}

	initializeStateFromProps(immediate) {
		const { appliedExternalPriceRule } = this.state;
		const { configuration, externalPriceRuleId, onLoadPriceRuleById, isPriceRuleLoadingById, getPriceRuleById } = this.props;
		if (!this.state.configuration && configuration) {
			this.setState({
				configuration: { ...configuration, enabled: true }
			});
			if (configuration.backupCode && !externalPriceRuleId) {
				this.attemptToLoadBackupCode(configuration.backupCode, false, immediate);
			}
		}
		if (externalPriceRuleId && !appliedExternalPriceRule) {
			if (!isPriceRuleLoadingById(externalPriceRuleId) && !getPriceRuleById(externalPriceRuleId)) {
				onLoadPriceRuleById(externalPriceRuleId);
			} else if (getPriceRuleById(externalPriceRuleId)) {
				this.setState({
					configuration: {
						...configuration,
						backupCode: getPriceRuleById(externalPriceRuleId).code,
						backupType: 'discount'
					},
					dirty: true,
					appliedExternalPriceRule: true
				});
			}
		}
	}

	attemptToLoadBackupCode = (code, cdnCache, immediate) => {
		const { onLoadPriceRule, getPriceRule, getPriceRuleError } = this.props;
		clearTimeout(this.loadBackupCodeTimeout);
		if (code) {
			if (!getPriceRule(code) && !getPriceRuleError(code)) {
				this.loadBackupCodeTimeout = setTimeout(
					() => {
						onLoadPriceRule(code, cdnCache);
					},
					immediate ? 0 : LOAD_BACKUP_DEBOUNCE
				);
			}
		}
	};

	configurationChanged = configuration => {
		if (configuration.backupCode) {
			this.attemptToLoadBackupCode(configuration.backupCode, true);
		}
		this.setState({
			configuration: { ...configuration },
			dirty: true
		});
	};

	discardChanges = () => {
		const { configuration } = this.props;
		this.setState({
			configuration,
			dirty: false,
			errors: []
		});
	};

	saveChanges = () => {
		if (this.isValid()) {
			this.performSave();
		} else {
			window.scrollTo(0, 0);
		}
	};

	performSave = () => {
		const { onSaveBannerConfiguration } = this.props;
		const { configuration } = this.state;
		const savedConfiguration = { ...configuration };
		if (savedConfiguration.backupEnabled) {
			window.location.hash = 'banner-deployed';
		}
		if (!savedConfiguration.firstDeployedAt && savedConfiguration.backupEnabled) {
			savedConfiguration.firstDeployedAt = new Date();
		}
		onSaveBannerConfiguration(savedConfiguration);
	};

	isValid() {
		const { getPriceRule } = this.props;
		const { configuration } = this.state;
		const newErrors = [];
		if (configuration.backupEnabled) {
			if (configuration.backupType === 'custom') {
				const totalAnnouncements = configuration.totalAnnouncements;
				const getConfForMessageNumber = (conf, messageNumber) => configuration[`${conf}${messageNumber > 0 ? messageNumber + 1 : ''}`];
				for (let i = 0; i < totalAnnouncements; i++) {
					const prefix = totalAnnouncements > 1 ? `Message ${i + 1} ` : '';
					if (isEmpty(getConfForMessageNumber('customLabel', i))) {
						newErrors.push(`${prefix}Message must not be blank.`);
					}
					if (getConfForMessageNumber('actionType', i) === 'email_capture') {
						if (isEmpty(getConfForMessageNumber('actionSuccessMessage', i))) {
							newErrors.push(`${prefix}Success message must not be blank.`);
						}
					} else if (getConfForMessageNumber('actionType', i) !== 'none') {
						const actionUrl = getConfForMessageNumber('actionUrl', i);
						if (isEmpty(actionUrl)) {
							newErrors.push(`${prefix}Action URL must not be blank.`);
						} else if (!isValidUrl(actionUrl)) {
							newErrors.push(
								<span>
									{prefix}Action URL must be a valid URL and start with <em>http://</em> or <em>https://</em>.
								</span>
							);
						}
						if (getConfForMessageNumber('actionType', i) === 'link_button' && isEmpty(getConfForMessageNumber('actionLabel', i))) {
							newErrors.push(`${prefix}Button text must not be blank.`);
						}
					}
				}
			} else if (configuration.backupType === 'freeShipping') {
				if (isEmpty(configuration.customFreeShippingMinimum) || Number(configuration.customFreeShippingMinimum) <= 0) {
					newErrors.push('Minimum order price must be greater than 0.');
				}
				if (isEmpty(configuration.customLabelFreeShippingWithMinimum)) {
					newErrors.push('Empty cart message must not be blank.');
				}
				if (isEmpty(configuration.customLabelFreeShippingWithMinimumRemaining)) {
					newErrors.push('Minimum remaining message must not be blank.');
				}
				if (isEmpty(configuration.customLabelFreeShippingWithMinimumReached)) {
					newErrors.push('Minimum reached message must not be blank.');
				}
			} else if (configuration.backupType === 'discount') {
				if (isEmpty(configuration.backupCode)) {
					newErrors.push('Discount code must not be blank.');
				}
				switch (configuration.backupCodeMessageType) {
					case 'singular':
						if (isEmpty(configuration.customLabelDiscount)) {
							newErrors.push('Message must not be blank.');
						}
						break;
					case 'dynamic':
						if (isEmpty(configuration.customLabelDiscountWithMinimum)) {
							newErrors.push('Empty cart message must not be blank.');
						}
						if (isEmpty(configuration.customLabelDiscountWithMinimumRemaining)) {
							newErrors.push('Minimum remaining message must not be blank.');
						}
						if (isEmpty(configuration.customLabelDiscountWithMinimumReached)) {
							newErrors.push('Minimum reached message must not be blank.');
						}
						break;
					case 'tiered':
						const totalTiers = configuration.totalTiers;
						if (isEmpty(configuration.customLabelDiscountWithMinimum)) {
							newErrors.push('Empty cart message must not be blank.');
						}
						if (isEmpty(configuration.customLabelDiscountWithMinimumRemaining)) {
							newErrors.push('Tier 1 minimum remaining message must not be blank.');
						}
						for (let i = 1; i <= totalTiers; i++) {
							if (isEmpty(configuration[`customLabelDiscount${i}WithMinimumRemaining`])) {
								newErrors.push(`Tier ${i + 1} minimum remaining message must not be blank.`);
							}
							if (!getPriceRule(configuration[`backupCode${i}`])) {
								// This is a new code, verify amount and minimum are set
							}
						}
						const minimumReachedSuffix = totalTiers === 0 ? '' : totalTiers;
						if (isEmpty(configuration[`customLabelDiscount${minimumReachedSuffix}WithMinimumReached`])) {
							newErrors.push('Minimum reached message must not be blank.');
						}
						break;
					default:
						break;
				}
			}
		}
		if (configuration.position === 'element' && (!configuration.targetElement || configuration.targetElement.trim() === '')) {
			newErrors.push('Element CSS selector must not be blank.');
		}
		if (isEmpty(configuration.countdownDayLabel)) {
			newErrors.push('Countdown days label must not be blank.');
		}
		if (isEmpty(configuration.countdownHourLabel)) {
			newErrors.push('Countdown hours label must not be blank.');
		}
		if (isEmpty(configuration.countdownMinuteLabel)) {
			newErrors.push('Countdown minutes label must not be blank.');
		}
		if (isEmpty(configuration.countdownSecondLabel)) {
			newErrors.push('Countdown seconds label must not be blank.');
		}
		this.setState({
			errors: newErrors
		});
		return newErrors.length === 0;
	}

	renderErrors() {
		const { errors } = this.state;
		if (errors.length === 0) {
			return null;
		}
		return (
			<Layout.Section>
				<Banner
					title={`${errors.length === 1 ? 'Problem' : 'Problems'} with banner settings`}
					tone="critical"
					secondaryAction={{ content: 'Get support', onAction: activateSupport }}>
					<Stack spacing="tight" vertical>
						<p>
							The following errors are preventing the banner from being saved. Please correct the errors and try again or reach out to
							support if you need assistance.
						</p>
						<List type="bullet">
							{errors.map((error, index) => (
								<List.Item key={index}>{error}</List.Item>
							))}
						</List>
					</Stack>
				</Banner>
			</Layout.Section>
		);
	}

	render() {
		const {
			account,
			isBannerConfigurationLoading,
			isBannerConfigurationSaving,
			isPriceRuleLoading,
			getPriceRule,
			getPriceRuleError,
			isLoading
		} = this.props;
		const { configuration, dirty } = this.state;
		const disabled = !dirty || isBannerConfigurationLoading || isBannerConfigurationSaving || isPriceRuleLoading(configuration.backupCode);
		const saveDisabled = disabled || (configuration.backupType === 'discount' && getPriceRuleError(configuration.backupCode));
		const isLoadingScreenShowing = !configuration || isBannerConfigurationLoading;
		return (
			<Page>
				<ContextualSaveBar
					saveAction={{ loading: isBannerConfigurationSaving, disabled: !!saveDisabled, onAction: this.saveChanges }}
					discardAction={{ disabled, onAction: this.discardChanges, discardConfirmationModal: true }}
					visible={dirty}
				/>
				{isLoadingScreenShowing && <LoadingScreen />}
				{!isLoadingScreenShowing && (
					<BannerConfigContainer>
						<StickyContainer>
							<BannerPreview
								configuration={configuration}
								getPriceRule={getPriceRule}
								bannerCurrentlyShowing={!Boolean(this.props.configuration?.backupEnabled)}
							/>
						</StickyContainer>
						<Layout>
							{this.renderErrors()}
							<Layout.Section>
								<BannerConfigurationForm
									account={account}
									configuration={configuration}
									onConfigurationChanged={this.configurationChanged}
									getPriceRule={getPriceRule}
									getPriceRuleError={getPriceRuleError}
									isPriceRuleLoading={isPriceRuleLoading}
									bannerCurrentlyShowing={!Boolean(this.props.configuration?.backupEnabled)}
								/>
							</Layout.Section>
						</Layout>
					</BannerConfigContainer>
				)}
				{isLoading && <Loading />}
			</Page>
		);
	}
}

const StickyContainer = styled.div`
	position: sticky;
	top: 0;
	z-index: var(--p-z-index-2);
`;

const BannerConfigContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
