export function NoneIcon() {
	return (
		<g fill="none" fillRule="evenodd" strokeWidth="1">
			<g id="a" transform="translate(-166 -121)">
				<g transform="translate(122 40)">
					<g transform="translate(44 81)">
						<circle cx="30" cy="30" r="28" fill="#fff" />
						<path
							d="m30 52.5c-4.6689-2.216e-4 -9.2206-1.4624-13.016-4.1812l31.335-31.335c2.7189 3.7956 4.181 8.3473 4.1812 13.016 0 12.409-10.091 22.5-22.5 22.5m0-45c4.8525 0 9.3375 1.56 13.016 4.1812l-31.335 31.335c-2.7189-3.7956-4.181-8.3473-4.1812-13.016 0-12.409 10.091-22.5 22.5-22.5m0-7.5c-16.541 0-30 13.459-30 30s13.459 30 30 30 30-13.459 30-30-13.459-30-30-30"
							fill="currentColor"
						/>
					</g>
				</g>
			</g>
		</g>
	);
}

export function AnnouncementIcon() {
	return (
		<g fill="none" fillRule="evenodd" strokeWidth="1">
			<g transform="translate(-282 -126)">
				<g transform="translate(122 40)">
					<g transform="translate(163 89)">
						<polygon points="3.0597 37.385 58.67 37.385 49.863 3.3456 1.0396 29.572" fill="#fff" />
						<path
							d="m3.1818 36.818h54.545-54.545zm-2.987-10.14 3.2989 13.231-3.2989-13.231zm1.1793 2.4897 48.161-25.608-48.161 25.608zm47.953-28.808 9.8968 39.55-9.8968-39.55z"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="6"
						/>
						<path
							d="m14 35.818h-1.809c-0.44576 0-0.6074 0.046413-0.77037 0.13357-0.16296 0.087154-0.29086 0.21505-0.37801 0.37801-0.087154 0.16296-0.13357 0.32461-0.13357 0.77037v5.3268c0 1.3373 0.13924 1.8222 0.4007 2.3111 0.26146 0.48889 0.64514 0.87257 1.134 1.134 0.48889 0.26146 0.97382 0.4007 2.3111 0.4007h11.399c1.3373 0 1.8222-0.13924 2.3111-0.4007 0.48889-0.26146 0.87257-0.64514 1.134-1.134 0.26146-0.48889 0.4007-0.97382 0.4007-2.3111v-5.3268c0-0.44576-0.046413-0.6074-0.13357-0.77037-0.087154-0.16296-0.21505-0.29086-0.37801-0.37801-0.16296-0.087154-0.32461-0.13357-0.77037-0.13357h-1.7181v7.1818h-13v-7.1818z"
							fill="currentColor"
						/>
					</g>
				</g>
			</g>
		</g>
	);
}

export function FreeShippingIcon() {
	return (
		<g fill="none" fillRule="evenodd" strokeWidth="1">
			<g transform="translate(-405 -127)">
				<g transform="translate(122 40)">
					<g transform="translate(283 87)">
						<path
							d="m40.08 38.106-0.82256 0.8936h-5.2571v-17h22v17h-5.1157l-0.99609-1.0561c0.073205 0.34045 0.11175 0.69378 0.11175 1.0561 0 2.7614-2.2386 5-5 5s-5-2.2386-5-5c0-0.30502 0.027312-0.60365 0.079624-0.8936zm3.6236-3.9366c0.41355-0.11075 0.84825-0.16979 1.2967-0.16979 0.46405 0 0.91334 0.063218 1.3397 0.18151l-1.3397-1.4204-1.2967 1.4087zm-23.978 3.1914c0.17802 0.51339 0.27474 1.0648 0.27474 1.6388 0 2.7614-2.2386 5-5 5s-5-2.2386-5-5c0-0.12842 0.0048414-0.25571 0.014352-0.38169-0.068342 0.12527-0.13717 0.2525-0.20649 0.38169h-6.8079v-36h29v36h-11.25c-0.35448-0.5868-0.69616-1.1331-1.0251-1.6388zm-1.5868-2.2538c-1.2854-1.6515-2.3102-2.4773-3.0746-2.4773-0.69727 0-1.5649 0.68718-2.6029 2.0615 0.74417-0.4395 1.6121-0.69169 2.539-0.69169 1.1886 0 2.2804 0.41477 3.1385 1.1075z"
							fill="#fff"
						/>
						<path
							d="m54.084 36h-0.60778c-1.2368-3.4904-4.5596-6.0013-8.477-6.0013s-7.2402 2.5109-8.477 6.0013h-0.52285v-12.082h18.085v12.082zm-6.0857 3.002c0 1.6563-1.3429 2.9993-2.9991 2.9993s-2.9991-1.3431-2.9991-2.9993c0-1.6589 1.3429-3.002 2.9991-3.002s2.9991 1.3431 2.9991 3.002zm-18-3.002h-6.5236c-1.2368-3.4904-4.557-6.0013-8.477-6.0013-3.9147 0-7.2402 2.5109-8.4744 6.0013h-0.52285v-30.001h23.998v30.001zm-18 3.002c0-1.6589 1.3429-3.002 2.9991-3.002 1.6588 0 3.0017 1.3431 3.0017 3.002 0 1.6563-1.3429 2.9993-3.0017 2.9993-1.6561 0-2.9991-1.3431-2.9991-2.9993zm24.001-30.009h10.621l5.3771 9.0007h-15.999v-9.0007zm23.555 10.519h0.0053081l-8.7106-14.654s-1.5208-1.85-2.7602-1.85h-12.089v-0.0079628c0-1.651-1.3509-2.9993-3.0017-2.9993h-29.999c-1.6508 0-2.9991 1.3484-2.9991 2.9993v36.003c0 1.6483 1.3483 2.9993 2.9991 2.9993h3.5246c1.2368 3.493 4.5596 5.9987 8.4744 5.9987 3.92 0 7.2402-2.5056 8.477-5.9987h9.4298c0.029194 0.0026543 0.058389 0.010617 0.087584 0.010617h3.5325c1.2394 3.4877 4.5623 5.9881 8.4744 5.9881s7.2349-2.5003 8.4744-5.9881h3.5325c1.6455 0 2.9938-1.351 2.9938-3.002v-17.948c0-0.56802-0.16986-1.0962-0.44588-1.5501z"
							fill="currentColor"
						/>
					</g>
				</g>
			</g>
		</g>
	);
}

export function DiscountIcon() {
	return (
		<g fill="none" fillRule="evenodd" strokeWidth="1">
			<g transform="translate(-526 -121)">
				<g transform="translate(122 40)">
					<g transform="translate(409 86)">
						<g>
							<path
								d="m17.603 7.1429h-6.6146c-1.3373 0-1.8222 0.13924-2.3111 0.4007s-0.87257 0.64514-1.134 1.134-0.4007 0.97382-0.4007 2.3111v6.6146l-4.6773 4.6773c-0.9456 0.9456-1.19 1.387-1.3509 1.9175s-0.16082 1.0732 0 1.6038 0.40526 0.97193 1.3509 1.9175l4.6773 4.6773v6.6146c0 1.3373 0.13924 1.8222 0.4007 2.3111s0.64514 0.87257 1.134 1.134 0.97382 0.4007 2.3111 0.4007h6.6146l4.6773 4.6773c0.9456 0.9456 1.387 1.19 1.9175 1.3509 0.53058 0.16082 1.0732 0.16082 1.6038 0 0.53058-0.16082 0.97193-0.40526 1.9175-1.3509l4.6773-4.6773h6.6146c1.3373 0 1.8222-0.13924 2.3111-0.4007s0.87257-0.64514 1.134-1.134 0.4007-0.97382 0.4007-2.3111v-6.6146l4.6773-4.6773c0.9456-0.9456 1.19-1.387 1.3509-1.9175 0.16082-0.53058 0.16082-1.0732 0-1.6038-0.16082-0.53058-0.40526-0.97193-1.3509-1.9175l-4.6773-4.6773v-6.6146c0-1.3373-0.13924-1.8222-0.4007-2.3111s-0.64514-0.87257-1.134-1.134-0.97382-0.4007-2.3111-0.4007h-6.6146l-4.6773-4.6773c-0.9456-0.9456-1.387-1.19-1.9175-1.3509s-1.0732-0.16082-1.6038 0-0.97193 0.40526-1.9175 1.3509l-4.6773 4.6773z"
								fill="#fff"
							/>
							<path
								d="m16.361 4.1429 3.7986-3.7986c1.1824-1.1824 2.0295-1.7553 3.1687-2.1006 1.098-0.33279 2.2462-0.33279 3.3442 0 1.1391 0.34527 1.9863 0.91819 3.1687 2.1006l3.7986 3.7986h5.372c1.6721 0 2.6763 0.19391 3.7259 0.75526 1.0117 0.54106 1.8236 1.353 2.3647 2.3647 0.56135 1.0496 0.75526 2.0538 0.75526 3.7259v5.372l3.7986 3.7986c1.1824 1.1824 1.7553 2.0295 2.1006 3.1687 0.33279 1.098 0.33279 2.2462 0 3.3442-0.34527 1.1391-0.91819 1.9863-2.1006 3.1687l-3.7986 3.7986v5.372c0 1.6721-0.19391 2.6763-0.75526 3.7259-0.54106 1.0117-1.353 1.8236-2.3647 2.3647-1.0496 0.56135-2.0538 0.75526-3.7259 0.75526h-5.372l-3.7986 3.7986c-1.1824 1.1824-2.0295 1.7553-3.1687 2.1006-1.098 0.33279-2.2462 0.33279-3.3442 0-1.1391-0.34527-1.9863-0.91819-3.1687-2.1006l-3.7986-3.7986h-5.372c-1.6721 0-2.6763-0.19391-3.7259-0.75526-1.0117-0.54106-1.8236-1.353-2.3647-2.3647-0.56135-1.0496-0.75526-2.0538-0.75526-3.7259v-5.372l-3.7986-3.7986c-1.1824-1.1824-1.7553-2.0295-2.1006-3.1687-0.33279-1.098-0.33279-2.2462 0-3.3442 0.34527-1.1391 0.91819-1.9863 2.1006-3.1687l3.7986-3.7986v-5.372c0-1.6721 0.19391-2.6763 0.75526-3.7259 0.54106-1.0117 1.353-1.8236 2.3647-2.3647 1.0496-0.56135 2.0538-0.75526 3.7259-0.75526h5.372z"
								stroke="currentColor"
								strokeWidth="6"
							/>
						</g>
						<path
							d="m17.113 21.131c-2.219 0-4.0179-1.7989-4.0179-4.0179 0-2.219 1.7989-4.0179 4.0179-4.0179 2.219 0 4.0179 1.7989 4.0179 4.0179 0 2.219-1.7989 4.0179-4.0179 4.0179zm15.774 15.774c-2.219 0-4.0179-1.7989-4.0179-4.0179 0-2.219 1.7989-4.0179 4.0179-4.0179 2.219 0 4.0179 1.7989 4.0179 4.0179 0 2.219-1.7989 4.0179-4.0179 4.0179zm-15.589-0.66681c-0.97631 0.97631-2.5592 0.97631-3.5355 0-0.97631-0.97631-0.97631-2.5592 0-3.5355l18.94-18.94c0.97631-0.97631 2.5592-0.97631 3.5355 0 0.97631 0.97631 0.97631 2.5592 0 3.5355l-18.94 18.94z"
							fill="currentColor"
						/>
					</g>
				</g>
			</g>
		</g>
	);
}
