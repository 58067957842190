import { Loading, LoadingScreen, useQueryString } from '@segunosoftware/equinox';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import SettingsContainer from './containers/SettingsContainer';
import { useSetDefaultPriceRule } from './hooks/useExternal';

export default function App() {
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapper>
				<Suspense fallback={<LoadingScreen />}>
					<Routes>
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
					</Routes>
				</Suspense>
			</SentryWrapper>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="/external/*" element={<External />} />
					<Route path="" element={<SettingsContainer />} />
				</Routes>
			</Suspense>
			<SupportChat />
			{calculatedLoading && <Loading />}
		</>
	);
}

function External() {
	const { id } = useQueryString();
	const navigate = useNavigate();

	const { onSetDefaultPriceRuleId, isSuccess } = useSetDefaultPriceRule();

	useEffect(() => {
		if (typeof id === 'string') {
			onSetDefaultPriceRuleId(id);
		}
	}, [onSetDefaultPriceRuleId, id]);

	useEffect(() => {
		if (isSuccess) {
			navigate('/');
		}
	}, [isSuccess, navigate]);

	return null;
}
