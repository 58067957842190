import type { CSSProperties, PropsWithChildren } from 'react';
import { useState } from 'react';

export type SVGImageProps = PropsWithChildren<{
	viewBox: string;
	onClick: () => void;
	size?: number;
	color?: string;
	hoverColor?: string;
	style?: CSSProperties;
}>;

export default function SVGImage({
	viewBox,
	onClick,
	size = 20,
	color = '#000',
	hoverColor = '#000',
	style = {},
	children
}: SVGImageProps) {
	const [hovering, setHovering] = useState(false);

	const fillColor = hovering ? hoverColor : color;

	return (
		<svg
			viewBox={viewBox}
			style={{
				width: `${size}px`,
				height: `${size}px`,
				fill: fillColor,
				color: fillColor,
				cursor: typeof onClick === 'function' ? 'pointer' : 'default',
				...style
			}}
			onMouseOver={() => setHovering(true)}
			onMouseOut={() => setHovering(false)}
			onClick={onClick}>
			{children}
		</svg>
	);
}
