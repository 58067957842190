import { Card, Heading, Stack } from '@segunosoftware/equinox';
import { Button } from '@shopify/polaris';
import styled from 'styled-components';
import { activateSupport } from './SupportChat';

export default function AskForHelp() {
	return (
		<Card sectioned>
			<Container>
				<Stack alignment="center" vertical>
					<img src="/images/customer-support.svg" alt="Customer support" width="250" height="125" />
					<Heading>We're here to help!</Heading>
					<CenteredText>
						You can search help articles and talk with the Seguno team. Our regular business hours are weekdays from 9am - 5pm Eastern time,
						but leave us a message and we will get back to you as soon as possible.
					</CenteredText>
					<Button onClick={() => activateSupport()}>Get support</Button>
				</Stack>
			</Container>
		</Card>
	);
}

const Container = styled.div`
	max-width: 40rem;
	margin: 0 auto;
`;

const CenteredText = styled.p`
	text-align: center;
`;
