import { getCurrencySymbol } from '@segunosoftware/equinox';
import { FormLayout, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import MessageVariables from './MessageVariables';

export default class FreeShippingBanner extends Component {
	static propTypes = {
		configuration: PropTypes.shape({
			customFreeShippingMinimum: PropTypes.number,
			customLabelFreeShippingWithMinimum: PropTypes.string,
			customLabelFreeShippingWithMinimumRemaining: PropTypes.string,
			customLabelFreeShippingWithMinimumReached: PropTypes.string
		}).isRequired,
		onChange: PropTypes.func.isRequired,
		currency: PropTypes.string.isRequired
	};

	render() {
		const { configuration, onChange, currency } = this.props;
		return (
			<FormLayout>
				<TextField
					label="Minimum order price"
					type="number"
					autoComplete="off"
					min={0}
					prefix={getCurrencySymbol(currency)}
					onChange={newValue => onChange(Number(newValue), 'customFreeShippingMinimum')}
					value={(configuration.customFreeShippingMinimum || '').toString()}
					autoFocus
				/>
				<MessageVariables showAmountAndCode={false} />
				<TextField
					label="Empty cart message"
					autoComplete="off"
					id="customLabelFreeShippingWithMinimum"
					onChange={onChange}
					value={configuration.customLabelFreeShippingWithMinimum || ''}
				/>
				<TextField
					label="Minimum remaining message"
					autoComplete="off"
					id="customLabelFreeShippingWithMinimumRemaining"
					onChange={onChange}
					value={configuration.customLabelFreeShippingWithMinimumRemaining || ''}
				/>
				<TextField
					label="Minimum reached message"
					autoComplete="off"
					id="customLabelFreeShippingWithMinimumReached"
					onChange={onChange}
					value={configuration.customLabelFreeShippingWithMinimumReached || ''}
				/>
			</FormLayout>
		);
	}
}
