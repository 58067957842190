import { Stack } from '@segunosoftware/equinox';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { AnnouncementIcon, DiscountIcon, FreeShippingIcon, NoneIcon } from './BannerIcons';

export type BackupType = 'custom' | 'discount' | 'freeShipping';

export type SelectButtonContainerProps = {
	configuration: {
		backupEnabled: boolean;
		backupType: BackupType;
	};
	onClick: (backupType: BackupType | 'disabled') => void;
};

export default function SelectButtonContainer({ configuration: { backupEnabled, backupType }, onClick }: SelectButtonContainerProps) {
	return (
		<Stack alignment="center" distribution="equalSpacing">
			<SelectButton label="None" viewBox="0 0 60 60" color="#454F5B" onClick={() => onClick('disabled')} selected={!backupEnabled}>
				<NoneIcon />
			</SelectButton>
			<SelectButton
				label="Announcement"
				viewBox="0 0 66 50"
				color="#F49342"
				onClick={() => onClick('custom')}
				selected={backupEnabled && backupType === 'custom'}>
				<AnnouncementIcon />
			</SelectButton>
			<SelectButton
				label="Free shipping"
				viewBox="0 0 60 48"
				color="#F49342"
				onClick={() => onClick('freeShipping')}
				selected={backupEnabled && backupType === 'freeShipping'}>
				<FreeShippingIcon />
			</SelectButton>
			<SelectButton
				label="Discount"
				viewBox="0 0 60 60"
				color="#F49342"
				onClick={() => onClick('discount')}
				selected={backupEnabled && backupType === 'discount'}>
				<DiscountIcon />
			</SelectButton>
		</Stack>
	);
}

type SelectButtonProps = PropsWithChildren<{
	label: string;
	viewBox: string;
	onClick: () => void;
	selected?: boolean;
	color?: string;
	banner?: string;
}>;

function SelectButton({ label, viewBox, onClick, selected = false, color = '#454F5B', banner, children }: SelectButtonProps) {
	function onContainerClick() {
		!selected && onClick && onClick();
	}

	return (
		<Container onClick={onContainerClick} selected={selected}>
			{banner && <Banner>{banner}</Banner>}
			<Icon viewBox={viewBox} selected={selected} selectedColor={color}>
				{children}
			</Icon>
			<Label>{label}</Label>
		</Container>
	);
}

const Container = styled.div<{ selected: boolean }>`
	position: relative;
	overflow: hidden;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background-color: #f4f6f8;
	border: solid 1px ${({ selected }) => (selected ? '#637381' : '#c4cdd5')};
	padding: 10px;
	cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
	width: 120px;
	color: ${({ selected }) => (selected ? 'inherit' : '#637381')};
	&:hover {
		border-color: ${({ selected }) => (selected ? '#637381' : '#5c6ac4')};
	}
`;

const Label = styled.div`
	font-size: 13px;
	text-align: center;
	font-weight: 500;
`;

const Icon = styled.svg<{ selected: boolean; selectedColor: string }>`
	width: 60px;
	height: 60px;
	color: ${({ selected, selectedColor }) => (selected ? selectedColor : '#c4cdd5')};
	margin: 10px;
`;

const Banner = styled.div`
	position: absolute;
	top: 12%;
	left: 30%;
	width: 100%;
	transform: rotateZ(45deg);
	line-height: 1;
	padding: 3px;
	font-size: 11px;
	font-weight: 700;
	text-align: center;
	color: #fff;
	background-color: #5c6ac4;
`;
