import { Card, Stack, TextStyle } from '@segunosoftware/equinox';
import { Link, List } from '@shopify/polaris';
import type { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { useVerifyInstallation } from '../hooks/useVerifyInstallation';
import { AnnouncementIcon, DiscountIcon, FreeShippingIcon } from './BannerIcons';

type OnboardingCardProps = {
	shop: string;
	onDismiss: () => void;
};

export default function OnboardingCard({ shop, onDismiss }: OnboardingCardProps) {
	const { turnOnAppEmbedUrl } = useVerifyInstallation(shop);

	return (
		<Card title="Get started in 3 easy steps" actions={[{ content: 'Dismiss', onAction: onDismiss }]} sectioned>
			<Stack vertical>
				<List type="number">
					<List.Item>
						<p>
							<Link url={turnOnAppEmbedUrl} external>
								Turn on the app embed
							</Link>{' '}
							on your storefront. Once enabled, click Save in the theme editor and return to this app.
						</p>
					</List.Item>
					<List.Item>
						<Stack spacing="tight" vertical>
							<p>Select one of the banner types above to get started. The following banners are supported:</p>
							<Stack vertical>
								<BannerTypeDescription icon={<AnnouncementIcon />} viewBox="0 0 66 50" title="Announcement">
									Loop multiple messages or grow your subscriber list with an email capture form.
								</BannerTypeDescription>
								<BannerTypeDescription icon={<FreeShippingIcon />} viewBox="0 0 60 48" title="Free shipping">
									Help shoppers hit spend thresholds with real-time cart updates.
								</BannerTypeDescription>
								<BannerTypeDescription icon={<DiscountIcon />} viewBox="0 0 60 60" title="Discount">
									Create discount tiers that encourage shoppers to spend more.
								</BannerTypeDescription>
							</Stack>
						</Stack>
					</List.Item>
					<List.Item>
						<p>Customize the settings to your liking and click save to set it live on your storefront.</p>
					</List.Item>
				</List>
				<p>That's it! Use the get support button if you need assistance.</p>
			</Stack>
		</Card>
	);
}

type BannerTypeDescriptionProps = PropsWithChildren<{
	icon: ReactNode;
	viewBox: string;
	title: string;
}>;

function BannerTypeDescription({ icon, viewBox, title, children }: BannerTypeDescriptionProps) {
	return (
		<Stack alignment="center" wrap={false}>
			<BannerIcon viewBox={viewBox}>{icon}</BannerIcon>
			<Stack.Item fill>
				<Stack spacing="none" vertical>
					<TextStyle variation="strong">{title}</TextStyle>
					<p>{children}</p>
				</Stack>
			</Stack.Item>
		</Stack>
	);
}

const BannerIcon = styled.svg`
	width: 28px;
	height: 28px;
	color: rgb(48, 48, 48);
	margin: 5px;
`;
