import { Stack } from '@segunosoftware/equinox';
import { Banner, Layout } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import SVGImage from './SVGImage';
import { activateMessage } from './SupportChat';

const reviewLink = 'https://apps.shopify.com/coupon-banner-with-countdown-carousel?reveal_new_review=true';

const MILLIS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;
const DISPLAY_DAYS = 1;
const DISPLAY_DEFERRED_DAYS = 5;
const DISPLAY_DELAY_TIME = DISPLAY_DAYS * HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTE * MILLIS_PER_SECOND;
const DISPLAY_DEFERRED_DELAY_TIME = DISPLAY_DEFERRED_DAYS * HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTE * MILLIS_PER_SECOND;

export default class ReviewBanner extends Component {
	static propTypes = {
		account: PropTypes.shape({
			reviewSentiment: PropTypes.oneOf(['unspecified', 'happy', 'sad']).isRequired,
			reviewStatus: PropTypes.oneOf(['ignored', 'reviewed', 'denied', 'deferred']).isRequired,
			reviewRespondedAt: PropTypes.instanceOf(Date)
		}).isRequired,
		configuration: PropTypes.shape({
			firstDeployedAt: PropTypes.instanceOf(Date),
			backupEnabled: PropTypes.bool.isRequired
		}).isRequired,
		onSetReviewSentiment: PropTypes.func.isRequired,
		onSetReviewStatus: PropTypes.func.isRequired,
		navigate: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		const {
			account: { reviewSentiment, reviewStatus },
			configuration: { backupEnabled, firstDeployedAt }
		} = props;
		const now = new Date();
		const delayTimeReached = firstDeployedAt && now.getTime() - firstDeployedAt.getTime() >= DISPLAY_DELAY_TIME;
		const bannerVisible =
			delayTimeReached && backupEnabled && reviewSentiment !== 'sad' && (reviewStatus === 'ignored' || reviewStatus === 'deferred');
		this.state = {
			reviewSentiment,
			reviewStatus,
			bannerVisible,
			bannerHidden: false
		};
	}

	onDismiss = () => {
		this.setState({
			bannerHidden: true
		});
	};

	onHappySentiment = () => {
		this.setReviewSentiment('happy');
		this.sendToReview();
	};

	onSadSentiment = () => {
		this.setReviewSentiment('sad');
		this.submitATicket();
	};

	setReviewSentiment = reviewSentiment => {
		const { onSetReviewSentiment } = this.props;
		this.setState({
			reviewSentiment
		});
		onSetReviewSentiment(reviewSentiment);
	};

	setReviewStatus = reviewStatus => {
		const { onSetReviewStatus } = this.props;
		this.setState({
			reviewStatus
		});
		onSetReviewStatus(reviewStatus);
	};

	renderSentiment() {
		return (
			<Banner title="How was your experience?" tone="info">
				<Layout>
					<Layout.Section>
						Congratulations on deploying your banner! Please let us know how your initial experience made you feel by selecting the happy or
						sad face below.
					</Layout.Section>
					<Layout.Section>
						<Stack alignment="center" distribution="leading" spacing="none">
							<div style={styles.sentimentIcon}>
								<Stack alignment="center" spacing="none" vertical>
									<SVGImage viewBox="0 0 50 50" size={50} color="#637381" hoverColor="#01A0AC" onClick={this.onHappySentiment}>
										<path
											d="M16,30c0,3.32,4,6,9,6"
											style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
										/>
										<path
											d="M34,30c0,3.32-4,6-9,6"
											style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
										/>
										<path d="M25,1A24,24,0,1,0,49,25,24,24,0,0,0,25,1Zm0,44A20,20,0,1,1,45,25,20,20,0,0,1,25,45Z" />
										<circle cx="18.5" cy="19" r="2.5" />
										<circle cx="31.5" cy="19" r="2.5" />
									</SVGImage>
									<p>Write a review</p>
								</Stack>
							</div>
							<div style={styles.sentimentIcon}>
								<Stack alignment="center" spacing="none" vertical>
									<SVGImage viewBox="0 0 50 50" size={50} color="#637381" hoverColor="#01A0AC" onClick={this.onSadSentiment}>
										<path
											d="M16,36c0-3.32,4-6,9-6"
											style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
										/>
										<path
											d="M34,36c0-3.32-4-6-9-6"
											style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
										/>
										<path d="M25,1A24,24,0,1,0,49,25,24,24,0,0,0,25,1Zm0,44A20,20,0,1,1,45,25,20,20,0,0,1,25,45Z" />
										<circle cx="18.5" cy="19" r="2.5" />
										<circle cx="31.5" cy="19" r="2.5" />
									</SVGImage>
									<p>Talk to support</p>
								</Stack>
							</div>
						</Stack>
					</Layout.Section>
				</Layout>
			</Banner>
		);
	}

	sendToReview = () => {
		const { navigate } = this.props;
		navigate({
			to: reviewLink,
			newContext: true
		});
		this.setReviewStatus('reviewed');
		this.onDismiss();
	};

	remindMeLater = () => {
		this.setReviewStatus('deferred');
		this.onDismiss();
	};

	renderRequestReview() {
		return (
			<Banner title="Tell us more..." tone="info" action={{ content: 'Write a review', onAction: this.sendToReview }}>
				We are glad you had a nice experience and would love to hear your thoughts. Please take a minute to write a quick review. Reviews
				are incredibly helpful for us and your fellow merchants. Thank you!
			</Banner>
		);
	}

	submitATicket = () => {
		activateMessage('Feedback', 'Hello. I think Dynamic Banners could be improved by ');
		this.onDismiss();
	};

	reviewDenied = () => {
		this.setReviewStatus('denied');
		this.onDismiss();
	};

	renderProvideSupport() {
		return (
			<Banner
				title="Oh, no! How can we help?"
				tone="info"
				action={{ content: 'Send us a message', onAction: this.submitATicket }}
				onDismiss={this.onDismiss}>
				We are sorry to hear you did not have a stellar experience with your first discount set. We would love to know why so we can make it
				right and help ensure your needs will be met going forward. Please send us a message to share your thoughts.
			</Banner>
		);
	}

	renderReminderReview() {
		return (
			<Banner title="Still loving it?" tone="info" action={{ content: 'Write a review', onAction: this.sendToReview }}>
				We hope you have continued to have a great experience with this app. Your success is important to us and we would love to hear your
				thoughts. Please feel free to email us directly at help@seguno.com, but we would greatly appreciate you taking a minute to write a
				quick review. Reviews are incredibly helpful for us and your fellow merchants. Thank you!
			</Banner>
		);
	}

	renderReview() {
		const {
			account: { reviewRespondedAt }
		} = this.props;
		const { reviewSentiment, reviewStatus } = this.state;
		if (reviewStatus === 'deferred') {
			const now = new Date();
			if (reviewRespondedAt && now.getTime() - reviewRespondedAt.getTime() >= DISPLAY_DEFERRED_DELAY_TIME) {
				return this.renderReminderReview();
			}
			return null;
		}
		if (reviewSentiment === 'unspecified') {
			return this.renderSentiment();
		} else if (reviewSentiment === 'sad') {
			return this.renderProvideSupport();
		}
		return this.renderRequestReview();
	}

	render() {
		const { bannerVisible, bannerHidden } = this.state;
		if (bannerHidden || !bannerVisible) {
			return null;
		}
		return <Layout.Section>{this.renderReview()}</Layout.Section>;
	}
}

const styles = {
	sentimentIcon: {
		marginRight: '50px'
	}
};
