import { FormLayout, Select } from '@shopify/polaris';
import PropTypes from 'prop-types';

const FONT_NAMES = {
	arial: 'Arial',
	arial_black: 'Arial Black',
	bookman: 'Bookman',
	comic_sans: 'Comic Sans',
	courier: 'Courier',
	courier_new: 'Courier New',
	garamond: 'Garamond',
	georgia: 'Georgia',
	helvetica: 'Helvetica',
	impact: 'Impact',
	lucida_console: 'Lucida Console',
	lucida_sans: 'Lucida Sans',
	palatino: 'Palatino Linotype',
	tahoma: 'Tahoma',
	times: 'Times',
	times_new_roman: 'Times New Roman',
	trebuchet: 'Trebuchet MS',
	verdana: 'Verdana'
};

FontPicker.propTypes = {
	fontType: PropTypes.oneOf(['inherit', 'system', 'web']).isRequired,
	fontFamily: PropTypes.oneOf(Object.keys(FONT_NAMES)).isRequired,
	onFontChanged: PropTypes.func.isRequired
};

export default function FontPicker({ fontType, fontFamily, onFontChanged }) {
	function onFontTypeChanged(value) {
		onFontChanged(value, fontFamily);
	}

	function onFontFamilyChanged(value) {
		onFontChanged(fontType, value);
	}

	function getHelpText() {
		switch (fontType) {
			case 'system':
				return 'The font will be different for various systems or devices.';
			case 'inherit':
				return 'The font will match your storefront and the preview above may differ.';
			default:
				return null;
		}
	}

	return (
		<FormLayout>
			<FormLayout.Group>
				<Select
					label="Font type"
					value={fontType}
					helpText={getHelpText()}
					onChange={onFontTypeChanged}
					options={[
						{ value: 'system', label: 'Inherit from system or device' },
						{ value: 'inherit', label: 'Inherit from storefront' },
						{ value: 'web', label: 'Standard web font' }
					]}
				/>
				{fontType === 'web' && (
					<Select
						label="Font family"
						value={fontFamily}
						onChange={onFontFamilyChanged}
						options={Object.keys(FONT_NAMES).map(font => ({
							value: font,
							label: FONT_NAMES[font]
						}))}
					/>
				)}
			</FormLayout.Group>
		</FormLayout>
	);
}
