import { Banner, Layout } from '@shopify/polaris';
import { useVerifyInstallation } from '../hooks/useVerifyInstallation';

export type AppEmbedWarningBannerProps = {
	shop: string;
	usingScriptTag: boolean;
};

export default function AppEmbedWarningBanner({ shop, usingScriptTag }: AppEmbedWarningBannerProps) {
	const { verified, isLoading, turnOnAppEmbedUrl } = useVerifyInstallation(shop);

	if (verified || isLoading) {
		return null;
	}

	if (usingScriptTag) {
		return (
			<Layout.Section>
				<Banner
					title="Switch to app embed"
					tone="warning"
					action={{
						content: 'Turn on app embed',
						url: turnOnAppEmbedUrl,
						external: true
					}}>
					<p>
						Seguno Banners now requires an app embed be enabled on your online store. Please turn on the app embed and then refresh this
						page after you have made those changes on your theme.
					</p>
				</Banner>
			</Layout.Section>
		);
	}

	return (
		<Layout.Section>
			<Banner
				title="App embed not detected"
				tone="warning"
				action={{
					content: 'Turn on app embed',
					url: turnOnAppEmbedUrl,
					external: true
				}}>
				<p>
					The app embed does not appear to be activated. The app embed needs to be installed on your theme in order for the banner to
					display.
				</p>
			</Banner>
		</Layout.Section>
	);
}
