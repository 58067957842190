import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LOAD_BANNER_CONFIGURATION } from './query-keys';
import type { Get, Put } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export const useOnSaveBannerConfiguration = () => {
	const queryClient = useQueryClient();

	const { put } = useAuthenticatedFetch() as Put<any, any>;
	const { mutate: onSaveBannerConfiguration, isPending: isBannerConfigurationSaving } = useMutation({
		mutationFn: (payload: any) => put('/banner-configuration', payload),
		onSuccess: data => queryClient.setQueryData(queryKey(LOAD_BANNER_CONFIGURATION), data)
	});

	return { onSaveBannerConfiguration, isBannerConfigurationSaving };
};

export function useGetBannerConfiguration() {
	const { get } = useAuthenticatedFetch() as Get<any>;

	const { data: configuration, isPending: isBannerConfigurationLoading } = useQuery({
		queryKey: queryKey(LOAD_BANNER_CONFIGURATION),
		queryFn: () => get('/banner-configuration'),
		select: useDateTransformerFactory('customStartsAt', 'customEndsAt', 'firstDeployedAt')
	});

	return { configuration, isBannerConfigurationLoading };
}
