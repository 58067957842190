import { ColorPickerField, getCSSColorAsRGBA } from '@segunosoftware/equinox';
import { hsbToRgb, rgbToHsb, type HSBAColor, type RGBColor } from '@shopify/polaris';

export type ColorPickerProps = {
	onChange: (color: RGBColor) => void;
	color: string;
	label: string;
};

export default function ColorPicker({ color, label, onChange }: ColorPickerProps) {
	function onColorChange(color: HSBAColor) {
		onChange(hsbToRgb(color));
	}

	return <ColorPickerField color={{ ...rgbToHsb(getCSSColorAsRGBA(color)), alpha: 1 }} onChange={onColorChange} label={label} allowAlpha />;
}
