import { useNavigate } from '@segunosoftware/equinox';
import ReviewBanner from '../components/ReviewBanner';
import { useAccount, useReviewSentiment, useReviewStatus } from '../hooks/useAccount';
import { useGetBannerConfiguration } from '../hooks/useBannerConfiguration';

export default function ReviewBannerContainer() {
	const account = useAccount();
	const { configuration, isLoading } = useGetBannerConfiguration();
	const onSetReviewSentiment = useReviewSentiment();
	const onSetReviewStatus = useReviewStatus();
	const navigate = useNavigate();

	if (isLoading) {
		return null;
	}

	return (
		<ReviewBanner
			onSetReviewSentiment={onSetReviewSentiment}
			onSetReviewStatus={onSetReviewStatus}
			navigate={navigate}
			account={account}
			configuration={configuration}
		/>
	);
}
