import { getCurrencySymbol } from '@segunosoftware/equinox';
import { FormLayout, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

const LOAD_DISCOUNT_DEBOUNCE = 1000;

export default class DiscountTier extends Component {
	static propTypes = {
		account: PropTypes.shape({
			currency: PropTypes.string.isRequired
		}).isRequired,
		type: PropTypes.oneOf(['fixed_amount', 'percentage', 'shipping']).isRequired,
		onChange: PropTypes.func.isRequired,
		onMultiChange: PropTypes.func.isRequired,
		onLoadPriceRule: PropTypes.func.isRequired,
		getPriceRule: PropTypes.func.isRequired,
		isPriceRuleLoading: PropTypes.func.isRequired,
		getPriceRuleError: PropTypes.func.isRequired,
		configuration: PropTypes.object.isRequired,
		codeKey: PropTypes.string.isRequired,
		minimumKey: PropTypes.string.isRequired,
		amountKey: PropTypes.string.isRequired,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		disabled: false
	};

	componentDidMount() {
		this.loadDiscount(true);
	}

	componentDidUpdate(prevProps, prevState) {
		const { configuration } = prevProps;
		if (this.getCode() !== this.getCode(configuration)) {
			this.loadDiscount(false, true);
		}
	}

	componentWillUnmount() {
		this.stopLoadingDiscount();
	}

	stopLoadingDiscount() {
		clearTimeout(this.loadDiscountTimer);
	}

	loadDiscount(immediate, cacheCdn) {
		const { disabled, onLoadPriceRule, isPriceRuleLoading, getPriceRuleError } = this.props;
		this.stopLoadingDiscount();
		const code = this.getCode();
		if (disabled || isPriceRuleLoading(code) || getPriceRuleError(code) || this.getPriceRule(code)) {
			return;
		}
		this.loadDiscountTimer = setTimeout(
			() => {
				if (code) {
					onLoadPriceRule(code, cacheCdn);
				}
			},
			code && !immediate ? LOAD_DISCOUNT_DEBOUNCE : 0
		);
	}

	getCode(configuration = this.props.configuration) {
		const { codeKey } = this.props;
		return configuration[codeKey];
	}

	getPriceRule() {
		const { getPriceRule } = this.props;
		const code = this.getCode();
		return code && getPriceRule(code);
	}

	onMinimumChanged = (value, id) => {
		const { onChange } = this.props;
		const priceRule = this.getPriceRule();
		const numberValue = Number(value);
		if (priceRule && Number(priceRule.subtotalMinimum) === numberValue) {
			onChange(null, id);
		} else {
			onChange(numberValue, id);
		}
	};

	onAmountChanged = (value, id) => {
		const { onChange } = this.props;
		const priceRule = this.getPriceRule();
		const numberValue = Number(value);
		if (priceRule && Number(priceRule.amount) === numberValue) {
			onChange(null, id);
		} else {
			onChange(numberValue, id);
		}
	};

	getMinimumValue() {
		const { configuration, minimumKey, disabled } = this.props;
		const priceRule = this.getPriceRule();
		const value = configuration[minimumKey];
		if (priceRule && (disabled || (!value && value !== ''))) {
			return priceRule.subtotalMinimum;
		}
		return value;
	}

	getAmountValue() {
		const { configuration, amountKey, disabled } = this.props;
		const priceRule = this.getPriceRule();
		const value = configuration[amountKey];
		if (priceRule && (disabled || (!value && value !== ''))) {
			return priceRule.amount;
		}
		return value;
	}

	render() {
		const { account, type, onChange, codeKey, minimumKey, amountKey, isPriceRuleLoading, disabled } = this.props;
		const code = this.getCode();
		const isLoading = isPriceRuleLoading(code);
		const priceRule = this.getPriceRule();
		const actualType = (priceRule && priceRule.type) || type;
		return (
			<FormLayout>
				<FormLayout.Group condensed>
					<TextField
						type="text"
						label={isLoading ? 'Discount code (Loading...)' : 'Discount code'}
						autoComplete="on"
						id={codeKey}
						onChange={onChange}
						value={code || ''}
						disabled={disabled}
					/>
					<TextField
						type="number"
						label="Minimum purchase"
						autoComplete="off"
						min={0}
						id={minimumKey}
						onChange={this.onMinimumChanged}
						value={(this.getMinimumValue() ?? '').toString()}
						prefix={getCurrencySymbol(account.currency)}
						disabled={disabled || isLoading}
					/>
					<TextField
						type="number"
						label="Discount value"
						autoComplete="off"
						min={0}
						id={amountKey}
						onChange={this.onAmountChanged}
						value={(this.getAmountValue() ?? '').toString()}
						prefix={actualType === 'fixed_amount' ? getCurrencySymbol(account.currency) : undefined}
						suffix={actualType === 'fixed_amount' ? undefined : '%'}
						disabled={disabled || isLoading}
					/>
				</FormLayout.Group>
			</FormLayout>
		);
	}
}
