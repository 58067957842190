import { getMoney, Heading, Stack } from '@segunosoftware/equinox';
import { Button, Checkbox, ChoiceList, FormLayout, Select, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import DiscountTierContainer from '../containers/DiscountTierContainer';
import MessageVariables from './MessageVariables';

const MAXIMUM_TIERS = 4;

export default class DiscountBanner extends Component {
	static propTypes = {
		account: PropTypes.shape({
			currency: PropTypes.string.isRequired
		}).isRequired,
		configuration: PropTypes.shape({
			backupCodeMessageType: PropTypes.oneOf(['singular', 'dynamic', 'tiered']).isRequired,
			backupCode: PropTypes.string,
			customLabelDiscount: PropTypes.string,
			customLabelDiscountWithMinimum: PropTypes.string,
			customLabelDiscountWithMinimumRemaining: PropTypes.string,
			customLabelDiscountWithMinimumReached: PropTypes.string,
			customLabelDiscountProgressLabel: PropTypes.string,
			customLabelDiscountProgressValue: PropTypes.string,
			customLabelDiscountProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			backupCode1: PropTypes.string,
			backupCode2: PropTypes.string,
			backupCode3: PropTypes.string,
			backupCode4: PropTypes.string,
			backupCode5: PropTypes.string,
			customLabelDiscount1WithMinimumRemaining: PropTypes.string,
			customLabelDiscount1WithMinimumReached: PropTypes.string,
			customLabelDiscount1ProgressLabel: PropTypes.string,
			customLabelDiscount1ProgressValue: PropTypes.string,
			customLabelDiscount1ProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			customLabelDiscount2WithMinimumRemaining: PropTypes.string,
			customLabelDiscount2WithMinimumReached: PropTypes.string,
			customLabelDiscount2ProgressLabel: PropTypes.string,
			customLabelDiscount2ProgressValue: PropTypes.string,
			customLabelDiscount2ProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			customLabelDiscount3WithMinimumRemaining: PropTypes.string,
			customLabelDiscount3WithMinimumReached: PropTypes.string,
			customLabelDiscount3ProgressLabel: PropTypes.string,
			customLabelDiscount3ProgressValue: PropTypes.string,
			customLabelDiscount3ProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			customLabelDiscount4WithMinimumRemaining: PropTypes.string,
			customLabelDiscount4WithMinimumReached: PropTypes.string,
			customLabelDiscount4ProgressLabel: PropTypes.string,
			customLabelDiscount4ProgressValue: PropTypes.string,
			customLabelDiscount4ProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			customLabelDiscount5WithMinimumRemaining: PropTypes.string,
			customLabelDiscount5WithMinimumReached: PropTypes.string,
			customLabelDiscount5ProgressLabel: PropTypes.string,
			customLabelDiscount5ProgressValue: PropTypes.string,
			customLabelDiscount5ProgressIcon: PropTypes.oneOf(['none', 'discount', 'free_shipping', 'gift']).isRequired,
			totalTiers: PropTypes.number.isRequired,
			customDisplayProducts: PropTypes.bool,
			disableAutoApply: PropTypes.bool
		}).isRequired,
		onChange: PropTypes.func.isRequired,
		onMultiChange: PropTypes.func.isRequired,
		currency: PropTypes.string.isRequired,
		getPriceRuleError: PropTypes.func.isRequired,
		getPriceRule: PropTypes.func.isRequired,
		isPriceRuleLoading: PropTypes.func.isRequired
	};

	state = {
		defaultsSet: false
	};

	componentDidMount() {
		this.setDefaultLabels();
	}

	componentDidUpdate(prevProps, prevState) {
		this.setDefaultLabels();
	}

	setDefaultLabels() {
		const { configuration, onMultiChange, getPriceRule } = this.props;
		const { defaultsSet } = this.state;
		const backupCode = getPriceRule(configuration.backupCode);
		if (!backupCode || defaultsSet) {
			return;
		}
		this.setState({
			defaultsSet: true
		});
		const changes = {};
		if (!configuration.customLabelDiscount) {
			changes.customLabelDiscount =
				(backupCode.type === 'fixed_amount' && configuration.labelFixedAmount) ||
				(backupCode.type === 'percentage' && configuration.labelPercentage) ||
				(backupCode.type === 'shipping' && configuration.labelShipping);
		}
		if (!configuration.customLabelDiscountWithMinimum) {
			changes.customLabelDiscountWithMinimum =
				(backupCode.type === 'fixed_amount' && configuration.labelFixedAmountWithMinimum) ||
				(backupCode.type === 'percentage' && configuration.labelPercentageWithMinimum) ||
				(backupCode.type === 'shipping' && configuration.labelShippingWithMinimum);
		}
		if (!configuration.customLabelDiscountWithMinimumRemaining) {
			changes.customLabelDiscountWithMinimumRemaining =
				(backupCode.type === 'fixed_amount' && configuration.labelFixedAmountWithMinimumRemaining) ||
				(backupCode.type === 'percentage' && configuration.labelPercentageWithMinimumRemaining) ||
				(backupCode.type === 'shipping' && configuration.labelShippingWithMinimumRemaining);
		}
		if (!configuration.customLabelDiscountWithMinimumReached) {
			changes.customLabelDiscountWithMinimumReached =
				(backupCode.type === 'fixed_amount' && configuration.labelFixedAmountWithMinimumReached) ||
				(backupCode.type === 'percentage' && configuration.labelPercentageWithMinimumReached) ||
				(backupCode.type === 'shipping' && configuration.labelShippingWithMinimumReached);
		}
		if (Object.keys(changes).length > 0) {
			onMultiChange(changes);
		}
	}

	getBackupCodeHelpText = () => {
		const { isPriceRuleLoading, getPriceRule, configuration, currency } = this.props;
		if (isPriceRuleLoading(configuration.backupCode)) {
			return 'Checking code...';
		}
		const backupCode = getPriceRule(configuration.backupCode);
		if (!backupCode) {
			return null;
		}
		const minimumSuffix = backupCode.subtotalMinimum > 0 ? ` with a ${getMoney(backupCode.subtotalMinimum, currency)} minimum` : '';
		switch (backupCode.type) {
			case 'fixed_amount':
				return `This is a fixed amount code for $${backupCode.amount} off${minimumSuffix}.`;
			case 'percentage':
				return `This is a percentage discount code for ${backupCode.amount}% off${minimumSuffix}.`;
			case 'shipping':
				return `This is a free shipping discount${minimumSuffix}.`;
			default:
				break;
		}
	};

	onMessageTypeChanged = selections => {
		const { onChange } = this.props;
		onChange(selections[0], 'backupCodeMessageType');
	};

	renderStatic() {
		const { configuration, onChange } = this.props;
		return [
			<MessageVariables key="messageVariables" showRemaining={false} />,
			<TextField
				type="text"
				label="Message"
				autoComplete="off"
				id="customLabelDiscount"
				key="customLabelDiscount"
				onChange={onChange}
				value={configuration.customLabelDiscount || ''}
			/>
		];
	}

	renderDynamic() {
		const { configuration, onChange } = this.props;
		return [
			<MessageVariables key="messageVariables" />,
			<TextField
				type="text"
				label="Empty cart message"
				autoComplete="off"
				key="customLabelDiscountWithMinimum"
				id="customLabelDiscountWithMinimum"
				onChange={onChange}
				value={configuration.customLabelDiscountWithMinimum || ''}
			/>,
			<TextField
				type="text"
				label="Minimum remaining message"
				autoComplete="off"
				key="customLabelDiscountWithMinimumRemaining"
				id="customLabelDiscountWithMinimumRemaining"
				onChange={onChange}
				value={configuration.customLabelDiscountWithMinimumRemaining || ''}
			/>,
			<TextField
				type="text"
				label="Minimum reached message"
				autoComplete="off"
				key="customLabelDiscountWithMinimumReached"
				id="customLabelDiscountWithMinimumReached"
				onChange={onChange}
				value={configuration.customLabelDiscountWithMinimumReached || ''}
			/>
		];
	}

	onDeleteTier = tier => {
		const { configuration, onMultiChange } = this.props;
		const totalTiers = configuration.totalTiers;
		const changes = { totalTiers: totalTiers - 1 };
		if (totalTiers === 1) {
			changes['customLabelDiscountWithMinimumReached'] = configuration['customLabelDiscount1WithMinimumReached'];
			changes['customLabelDiscountProgressValue'] = configuration['customLabelDiscount1ProgressValue'];
			changes['customLabelDiscountProgressLabel'] = configuration['customLabelDiscount1ProgressLabel'];
			changes['customLabelDiscountProgressIcon'] = configuration['customLabelDiscount1ProgressIcon'];
		}
		for (let i = tier; i < totalTiers; i++) {
			changes[`backupCode${i}`] = configuration[`backupCode${i + 1}`];
			changes[`customLabelDiscount${i}WithMinimumRemaining`] = configuration[`customLabelDiscount${i + 1}WithMinimumRemaining`];
			changes[`customLabelDiscount${i}WithMinimumReached`] = configuration[`customLabelDiscount${i + 1}WithMinimumReached`];
			changes[`customLabelDiscount${i}ProgressValue`] = configuration[`customLabelDiscount${i + 1}ProgressValue`];
			changes[`customLabelDiscount${i}ProgressLabel`] = configuration[`customLabelDiscount${i + 1}ProgressLabel`];
			changes[`customLabelDiscount${i}ProgressIcon`] = configuration[`customLabelDiscount${i + 1}ProgressIcon`];
			changes[`tierMinimum${i}`] = configuration[`tierMinimum${i + 1}`];
			changes[`tierAmount${i}`] = configuration[`tierAmount${i + 1}`];
		}
		for (let i = totalTiers; i <= MAXIMUM_TIERS; i++) {
			changes[`backupCode${i}`] = null;
			changes[`customLabelDiscount${i}WithMinimumRemaining`] = null;
			changes[`customLabelDiscount${i}WithMinimumReached`] = null;
			changes[`customLabelDiscount${i}ProgressValue`] = null;
			changes[`customLabelDiscount${i}ProgressLabel`] = null;
			changes[`customLabelDiscount${i}ProgressIcon`] = 'discount';
			changes[`tierMinimum${i}`] = null;
			changes[`tierAmount${i}`] = null;
		}
		onMultiChange(changes);
	};

	renderTier(tier) {
		const { configuration, onChange, onMultiChange, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const key = tier === 0 ? 'customLabelDiscountWithMinimumRemaining' : `customLabelDiscount${tier}WithMinimumRemaining`;
		const codeKey = tier === 0 ? 'backupCode' : `backupCode${tier}`;
		const minimumKey = `tierMinimum${tier}`;
		const amountKey = `tierAmount${tier}`;
		const tierNumber = tier + 1;
		const fields = [
			<Stack distribution="equalSpacing" key={`stack${key}`}>
				<Heading>Tier {tierNumber}</Heading>
				{tier > 0 && (
					<Button onClick={() => this.onDeleteTier(tier)} variant="plain">
						Delete tier
					</Button>
				)}
			</Stack>,
			<DiscountTierContainer
				key={`tierCode${key}`}
				type={backupCode.type}
				configuration={configuration}
				onChange={onChange}
				onMultiChange={onMultiChange}
				codeKey={codeKey}
				minimumKey={minimumKey}
				amountKey={amountKey}
				disabled={tier === 0}
			/>
		];
		if (tier === 0) {
			fields.push(
				<TextField
					key={`emptyCart${key}`}
					type="text"
					label="Empty cart message"
					autoComplete="off"
					id="customLabelDiscountWithMinimum"
					onChange={onChange}
					value={configuration.customLabelDiscountWithMinimum || ''}
				/>
			);
		}
		fields.push(
			<TextField
				key={`minimumRemaining${tier}`}
				type="text"
				label="Minimum remaining message"
				autoComplete="off"
				id={key}
				onChange={onChange}
				value={configuration[key] || ''}
			/>
		);
		if (configuration.tierProgressEnabled) {
			fields.push(
				<FormLayout key={`progress${tier}`}>
					<FormLayout.Group condensed>
						<TextField
							type="text"
							label="Progress tier top label"
							autoComplete="off"
							id={`customLabelDiscount${tier === 0 ? '' : tier}ProgressValue`}
							onChange={onChange}
							value={configuration[`customLabelDiscount${tier === 0 ? '' : tier}ProgressValue`] || ''}
						/>
						<TextField
							type="text"
							label="Progress tier bottom label"
							autoComplete="off"
							id={`customLabelDiscount${tier === 0 ? '' : tier}ProgressLabel`}
							onChange={onChange}
							value={configuration[`customLabelDiscount${tier === 0 ? '' : tier}ProgressLabel`] || ''}
						/>
						<Select
							key={`progressIcon${tier}`}
							label="Progress tier icon"
							options={[
								{ label: 'None', value: 'none' },
								{ label: 'Discount', value: 'discount' },
								{ label: 'Shipping', value: 'free_shipping' },
								{ label: 'Gift', value: 'gift' }
							]}
							id={`customLabelDiscount${tier === 0 ? '' : tier}ProgressIcon`}
							onChange={onChange}
							value={configuration[`customLabelDiscount${tier === 0 ? '' : tier}ProgressIcon`]}
						/>
					</FormLayout.Group>
				</FormLayout>
			);
		}
		return fields;
	}

	onAddTier = () => {
		const { configuration, onMultiChange, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const totalTiers = configuration.totalTiers;
		const changes = {
			totalTiers: totalTiers + 1
		};
		const tierLabel = totalTiers === 0 ? '' : totalTiers;
		changes[`customLabelDiscount${totalTiers + 1}WithMinimumReached`] = configuration[`customLabelDiscount${tierLabel}WithMinimumReached`];
		const amountPrefix = backupCode.type === 'fixed_amount' ? '$' : '';
		const amountSuffix = backupCode.type === 'fixed_amount' ? '' : '%';
		changes[`customLabelDiscount${totalTiers + 1}WithMinimumRemaining`] =
			`You've got ${amountPrefix}{earned-amount}${amountSuffix} off with code {earned-code}. Spend \${remaining} more to get ${amountPrefix}{amount}${amountSuffix} off.`;
		onMultiChange(changes);
	};

	renderTiered() {
		const { configuration, onChange } = this.props;
		const fields = [<MessageVariables key="messageVariables" showEarned />];
		fields.push(...this.renderTier(0));
		for (let i = 0; i < configuration.totalTiers; i++) {
			fields.push(...this.renderTier(i + 1));
		}
		const reachedKey =
			configuration.totalTiers === 0
				? 'customLabelDiscountWithMinimumReached'
				: `customLabelDiscount${configuration.totalTiers}WithMinimumReached`;
		fields.push(
			<TextField
				key={reachedKey}
				type="text"
				label="Minimum reached message"
				autoComplete="off"
				id={reachedKey}
				onChange={onChange}
				value={configuration[reachedKey] || ''}
			/>
		);
		if (configuration.totalTiers < MAXIMUM_TIERS) {
			fields.push(
				<Button key="addTier" onClick={this.onAddTier} size="slim" variant="primary">
					Add tier
				</Button>
			);
		}
		return fields;
	}

	renderSettings() {
		const { configuration, getPriceRule } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const backupCodeNoMinimum = backupCode && (!backupCode.subtotalMinimum || backupCode.subtotalMinimum <= 0);
		return (
			<FormLayout>
				{!backupCodeNoMinimum && (
					<MessageTypeContainer>
						<ChoiceList
							title="Message type"
							choices={[
								{ value: 'singular', label: 'Static: display same message regardless of cart value.' },
								{ value: 'dynamic', label: 'Dynamic: update message based on current cart value.' },
								{ value: 'tiered', label: 'Dynamic with tiers: encourage spending more to save more.' }
							]}
							onChange={this.onMessageTypeChanged}
							selected={[configuration.backupCodeMessageType]}
						/>
					</MessageTypeContainer>
				)}
				{(backupCodeNoMinimum || configuration.backupCodeMessageType === 'singular') && this.renderStatic()}
				{!backupCodeNoMinimum && configuration.backupCodeMessageType === 'dynamic' && this.renderDynamic()}
				{!backupCodeNoMinimum && configuration.backupCodeMessageType === 'tiered' && this.renderTiered()}
			</FormLayout>
		);
	}

	onBackupCodeChanged = backupCode => {
		const { onMultiChange, configuration } = this.props;
		const changes = {
			backupCode,
			totalTiers: 0,
			backupCodeMessageType: 'dynamic'
		};
		if (configuration.totalTiers > 0) {
			changes['customLabelDiscountWithMinimumReached'] = configuration[`customLabelDiscount${configuration.totalTiers}WithMinimumReached`];
		}
		for (let i = 1; i <= MAXIMUM_TIERS; i++) {
			changes[`backupCode${i}`] = null;
			changes[`customLabelDiscount${i}WithMinimumRemaining`] = null;
			changes[`customLabelDiscount${i}WithMinimumReached`] = null;
			changes[`tierMinimum${i}`] = null;
			changes[`tierAmount${i}`] = null;
		}
		onMultiChange(changes);
	};

	render() {
		const { configuration, getPriceRule, getPriceRuleError, isPriceRuleLoading, onChange } = this.props;
		const backupCode = getPriceRule(configuration.backupCode);
		const backupCodeError = getPriceRuleError(configuration.backupCode);
		const isBackupCodeLoaded = !isPriceRuleLoading(configuration.backup) && !!backupCode && !backupCodeError;
		return (
			<FormLayout>
				<TextField
					type="text"
					label="Discount code"
					autoComplete="off"
					onChange={this.onBackupCodeChanged}
					helpText={!backupCodeError && this.getBackupCodeHelpText()}
					error={backupCodeError}
					value={configuration.backupCode || ''}
					autoFocus
				/>
				{isBackupCodeLoaded && this.renderSettings()}
				{isBackupCodeLoaded && (
					<Checkbox
						label="Auto-apply discount"
						checked={!configuration.customDisableAutoApply}
						onChange={newValue => onChange(!newValue, 'customDisableAutoApply')}
					/>
				)}
				{isBackupCodeLoaded && configuration.totalTiers > 0 && (
					<Checkbox
						label="Show discount tiers progress bar"
						checked={configuration.tierProgressEnabled}
						onChange={newValue => onChange(newValue, 'tierProgressEnabled')}
					/>
				)}
				{isBackupCodeLoaded && configuration.totalTiers > 0 && configuration.tierProgressEnabled && (
					<Checkbox
						label="Evenly space discount tiers"
						checked={configuration.tierProgressEvenSpacing}
						onChange={newValue => onChange(newValue, 'tierProgressEvenSpacing')}
					/>
				)}
				{isBackupCodeLoaded && backupCode.products.length > 0 && (
					<Checkbox
						label="Show product carousel"
						id="customDisplayProducts"
						checked={configuration.customDisplayProducts}
						onChange={onChange}
					/>
				)}
			</FormLayout>
		);
	}
}

const MessageTypeContainer = styled.div`
	padding-top: 1rem;
`;
