import { useState } from 'react';
import DiscountTier from '../components/DiscountTier';
import { useAccount } from '../hooks/useAccount';
import { useLoadPriceRule } from '../hooks/usePriceRules';

export default function DiscountTierContainer(props) {
	const account = useAccount();

	const [queryConfig, setQueryConfig] = useState({
		code: null,
		cdnCache: null
	});

	const { priceRule, isPriceRuleLoading } = useLoadPriceRule(queryConfig);

	return (
		<DiscountTier
			{...props}
			getPriceRule={code => (queryConfig.code === code ? priceRule : undefined)}
			getPriceRuleError={code => (queryConfig.code === code ? priceRule?.error : undefined)}
			isPriceRuleLoading={code => (queryConfig.code === code ? isPriceRuleLoading : false)}
			onLoadPriceRule={(code, cdnCache) => setQueryConfig({ code, cdnCache })}
			account={account}
		/>
	);
}
